module.exports = {
    //ImageURL: 'http://localhost:3000',
    //GraphQL: 'http://192.168.0.53:4005',

    // ImageURL: 'https://dev-kb.octarian.com',
    // GraphQL: 'https://dev-kb.octarian.com/graphql'

    ImageURL: 'https://kb.octarian.com',
    GraphQL: 'https://kb.octarian.com/graphql'
}
