import React from "react";
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
// @material-ui/core components
import { Grid, Typography, TextField, IconButton, ListItem, Paper, Divider } from "@material-ui/core/"
import Avatar from '@material-ui/icons/AccountBox';
import { connect } from 'react-redux';
import MatLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import ArrowForwardIcon from '@material-ui/icons/ArrowRight';
import ArrowBackIcon from '@material-ui/icons/ArrowLeft';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NavPills from "components/NavPills/NavPills.js";
import withStyles from "@material-ui/core/styles/withStyles";
import jwt from 'jsonwebtoken'
// core components
import Button from "components/CustomButtons/Button.js";
import CommentButton from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import { ImageURL } from "../../apiPack";
import { GraphQL } from "../../apiPack";
import Delete from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import isUrl from "is-url"
import { $CombinedState } from "redux";

const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};

class ViewDetails extends React.Component {
    constructor() {
        super()
        this.postComment = this.postComment.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.deleteComment = this.deleteComment.bind(this)
        this.postNote = this.postNote.bind(this)
        this.deleteNote = this.deleteNote.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.rerenderForSimilar = this.rerenderForSimilar.bind(this)

        this.state = {
            dkbid: '',
            company: '',
            cvss: '',
            uuid: '',
            comments: [],
            controls: [],
            tags: [],
            cwe: [],
            nButton: '',
            lButton: '',
            all: '',
            timestamp: '',
            dkbid: '',
            tags: '',
            versions: '',
            title: '',
            description: '',
            cisBench: '',
            testProcedure: '',
            expected: '',
            rationalStatement: '',
            remediation: '',
            value: "",
            admin: false,
            updateUUID: {},
            versionHistory: false,
            timestamp: '',
            similarTab: [],
            parsedCWE: [],
            emptyAmount: []
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
              authorization: token ? `${token}` : '',
              "X-Frame-Options": "DENY",
              "X-Content-Type-Options": "nosniff",
              "X-XSS-Protection": 1,
              "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.location.aboutProps === undefined) {
            this.props.history.push('/admin/ViewVuln')
        } else if (this.props.loggedIn !== undefined) {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                this.setState({ updateUUID: decoded, admin: decoded.admin, uuid: decoded.uuid, company: decoded.company })
                console.log(this.props.location.aboutProps.name)
                const queryVariables = gql`
                query {
                    checklist (query: "${this.props.location.aboutProps.name}") {
                        dkbid,
                        uuid,
                        title,
                        description,
                        cisBench,
                        testProcedure,
                        expected,
                        rationalStatement,
                        remediation,
                        timestamp,
                        versions {
                            timestamp
                            version
                        }
                    },
                }`
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    console.log(response.data.checklist[0])
                    this.setState({
                        all: response.data.checklist[0],
                        timestamp: response.data.checklist[0].timestamp,
                        dkbid: response.data.checklist[0].dkbid,
                        tags: response.data.checklist[0].tag,
                        versions: response.data.checklist[0].versions.reverse(),
                        title: response.data.checklist[0].title,
                        description: Base64.decode(response.data.checklist[0].description),
                        cisBench: response.data.checklist[0].cisBench,
                        testProcedure: Base64.decode(response.data.checklist[0].testProcedure),
                        expected: "<p>" + response.data.checklist[0].expected + "</p>",
                        rationalStatement: Base64.decode(response.data.checklist[0].rationalStatement),
                        remediation: Base64.decode(response.data.checklist[0].remediation),
                    })
                    const surroundings = gql`
                    query {
                        surroundcheck(query: "${response.data.checklist[0].dkbid}") {
                            next,
                            prev
                        }
                    }`
                    this.client.query({ query: surroundings }).then((response) => {
                        console.log(response.data)
                        this.setState({
                            nButton: response.data.surroundcheck.next,
                            lButton: response.data.surroundcheck.prev
                        })
                    })
                })
            } catch (e) {
                this.props.history.push('/login')
            };
        } else {
            this.props.history.push('/login')
        }
    }

    rerenderForSimilar(uuid) {
        const queryVariables = gql`
        query {
            checklist (query: "${uuid}") {
                dkbid,
                uuid,
                title,
                description,
                cisBench,
                testProcedure,
                expected,
                rationalStatement,
                remediation,
                timestamp,
                versions {
                    owner{ 
                        name
                    }
                    timestamp
                    version
                }
            },
        }`
        this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
            console.log(response.data)
            this.setState({
                all: response.data.checklist[0],
                timestamp: response.data.checklist[0].timestamp,
                dkbid: response.data.checklist[0].dkbid,
                tags: response.data.checklist[0].tag,
                versions: response.data.checklist[0].versions.reverse(),
                title: response.data.checklist[0].title,
                description: Base64.decode(response.data.checklist[0].description),
                cisBench: response.data.checklist[0].cisBench,
                testProcedure: Base64.decode(response.data.checklist[0].testProcedure),
                expected: response.data.checklist[0].expected,
                rationalStatement: Base64.decode(response.data.checklist[0].rationalStatement),
                remediation: Base64.decode(response.data.checklist[0].remediation),
            })
            const surroundings = gql`
            query {
                surroundcheck(query: "${response.data.checklist[0].dkbid}") {
                    next,
                    prev
                }
            }`
            this.client.query({ query: surroundings }).then((response) => {
                console.log(response.data)
                this.setState({
                    nButton: response.data.surroundcheck.next,
                    lButton: response.data.surroundcheck.prev
                })
            })
        })
    }

    handleChange(e) {
        this.setState({
            value: e.target.value
        })
    }

    handleChange2(e) {
        this.setState({
            value2: e.target.value
        })
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "15px",
        }
        const body = {
            fontSize: 20,
            color: '#404040',
            fontFamily: 'Roboto'
        }
        const bodyContainer = {
            marginLeft: 10,
            marginRight: 10,
            marginTop: 6,
            marginBottom: 24,
        }
        const commentBody = {
            fontSize: 12
        }
        const timeStamp = {
            float: 'left',
            fontSize: 9,
            marginTop: 11,
            float: 'left',
            color: 'gray'
        }
        const commentAuthor = {
            color: 'black',
            marginRight: 8,
            marginTop: 5,
            float: 'left'
        }

        return (
            // Change this to the Check details stuff
            <div>
                <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"></link>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose"><ListAltIcon /></CardIcon>
                                <h4 style={cardIconTitle}>{this.state.all.title}
                                    {this.state.nButton === "Broken" ? null : <IconButton style={{ float: 'right', padding: 0, margin: 0, width: 28, height: 28 }} size="small" label="Next"><ArrowForwardIcon onClick={() => {this.rerenderForSimilar(this.state.nButton)}} style={{width: '36px', height: '36px'}} /></IconButton>}
                                    <Link to={{ pathname: '/admin/EditSpecificCheck', aboutProps: { name: this.state.all.uuid } }}><IconButton style={{ float: 'right', padding: 0, margin: 0, width: 28, height: 28 }} size="small" label="View"><EditIcon style={{ fontSize: 12, color: ViewVulnColor.infrastructure }} /></IconButton></Link>
                                    <IconButton style={{ float: 'right', padding: 0, margin: 0, width: 28, height: 28 }} size="small" label="Back"><ArrowBackIcon onClick={() => {this.rerenderForSimilar(this.state.lButton)}} style={{width: '36px', height: '36px'}} /></IconButton>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Typography style={cardIconTitle} gutterBottom>Description</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.description)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>Test Procedures</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.testProcedure)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>Expected</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.expected)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>Rationale</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.rationalStatement)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>Remediation</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.remediation)}</Typography>
                                </div>
                            </CardBody>
                        </Card >
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <NavPills
                            color="danger"
                            tabs={[
                                {
                                    tabButton: "Info",
                                    tabContent: (
                                        <Grid container style={{ width: '100%', height: 660 }}>
                                            <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                <div>
                                                    <Typography style={{ float: 'right', marginRight: 5, fontSize: 50, fontWeight: 8 }}>
                                                        {this.state.dkbid}
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Typography style={{ float: 'right', marginBottom: 15, fontSize: 15, fontWeight: 4, marginRight: 5 }}>
                                                        {moment.unix(this.state.timestamp).format('DD MMM YYYY')}
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            {this.state.cisBench.length !== 0 ? <><Grid item xs={12} style={{ marginBottom: 15, marginBottom: 15 }}>
                                                <div>
                                                    <Typography style={{ float: 'left' }}>
                                                        CIS Benchmark
                                                    </Typography>
                                                    <Typography style={{ float: 'right', marginRight: 5, fontSize: 15, fontWeight: 4, marginBottom: 15 }}>
                                                        {this.state.cisBench}
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Divider />
                                                </div>
                                            </Grid></>: null}
                                            {/* <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                <div>
                                                    <Typography style={{ float: 'left', marginTop: 14, marginBottom: 15 }}>
                                                        Version
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <IconButton onClick={() => this.setState({ versionHistory: !this.state.versionHistory })} style={{ float: 'right' }}>
                                                        <HistoryIcon style={{ color: 'grey' }} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Divider />
                                                </div>
                                            </Grid> */}
                                        </Grid>
                                    )
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
                {/* <Dialog
                    open={this.state.versionHistory}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'md'}
                >
                    <div style={{ width: 800 }}>
                        <DialogTitle id="alert-dialog-title">{"Version History"}</DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableHead>
                                    <TableCell>Version</TableCell>
                                    <TableCell>Timestamp</TableCell>
                                </TableHead>
                                <TableBody>
                                    {this.state.versions.map((item, index) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell>v{item.version}</TableCell>
                                                    <TableCell>{item.timestamp}</TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </DialogContent>
                    </div>
                </Dialog> */}
            </div>
        );
    }

    deleteComment(uuid) {
        const deleteCommentMutate = gql`
            mutation {
                deleteComment(
                    uuid: "${uuid}"
                )
            }
        `

        this.client.mutate({ mutation: deleteCommentMutate }).then(() => {
            const queryVariables = gql`
                query {
                    finding (query: "${this.props.location.aboutProps.name}") {
                        comment {uuid, content, owner{profilePicture}, name, timestamp}
                    },
                }
            `
            this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                this.setState({
                    comments: response.data.finding[0].comment.reverse(),
                    value: ""
                })
            })
        })
    }

    handleClose() {
        this.setState({ versionHistory: false })
    }

    deleteNote(uuid) {
        const deleteCommentMutate = gql`
            mutation {
                deleteNote(
                    uuid: "${uuid}"
                )
            }
        `

        this.client.mutate({ mutation: deleteCommentMutate }).then(() => {
            const queryVariables = gql`
                query {
                    finding (query: "${this.props.location.aboutProps.name}") {
                        note {uuid, content, owner{profilePicture}, name, timestamp}
                    },
                }
            `
            this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                this.setState({
                    notes: response.data.finding[0].note.reverse(),
                    value: ""
                })
            })
        })
    }

    postNote() {
        if (this.state.value2 !== "") {
            var newArray = []
            newArray.push("<p>")
            var splitArr = this.state.value2.split(" ")
            for (var ukl = 0; ukl<splitArr.length; ukl++) {
                var url = isUrl(splitArr[ukl])
                if (url === false) {
                    newArray.push(splitArr[ukl])
                } else {
                    newArray.push(`<a href="${splitArr[ukl]}" rel="noopener noreferrer" target="_blank">${splitArr[ukl]}</a>`)
                }
            }
            newArray.push("</p>")
            var timestamp = moment().format('DD MMMM YYYY')
            const queryVariables = gql`
            mutation {
                addNote(
                    owner: "${this.state.updateUUID.uuid}",
                    name: "${this.state.updateUUID.name}",
                    profilePicture: "${this.state.updateUUID.profilePicture}",
                    timestamp: "${timestamp}",
                    finding: "${this.state.all.uuid}",
                    content: "${Base64.encode(newArray.join(" "))}"
                )
                { owner }
            }`

            this.client.mutate({ mutation: queryVariables }).then(() => {
                const queryVariables = gql`
                    query {
                        finding (query: "${this.props.location.aboutProps.name}") {
                            note {content, owner{profilePicture}, name, timestamp}
                        },
                    }
                `
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    this.setState({
                        notes: response.data.finding[0].note.reverse(),
                        value2: ""
                    })
                })
            })
        }
    }

    postComment() {
        if (this.state.value !== "") {
            var timestamp = moment().format('DD MMMM YYYY')
            console.log(this.state.updateUUID)
            const queryVariables = gql`
            mutation {
                addComment (
                    owner: "${this.state.updateUUID.uuid}",
                    name: "${this.state.updateUUID.name}",
                    profilePicture: "${this.state.updateUUID.profilePicture}",
                    timestamp: "${timestamp}",
                    finding: "${this.state.all.uuid}",
                    content: "${this.state.value}"
                )
                { owner }
            }`

            this.client.mutate({ mutation: queryVariables }).then(() => {
                const queryVariables = gql`
                    query {
                        finding (query: "${this.props.location.aboutProps.name}") {
                            comment {content, owner{profilePicture}, name, timestamp}
                        },
                    }
                `
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    this.setState({
                        comments: response.data.finding[0].comment.reverse(),
                        value: ""
                    })
                })
            })
        }
    }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewDetails));