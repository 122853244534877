import React from "react"
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag"
// @material-ui/core components
import { TextField, Grid, Typography, Switch, FormControlLabel } from "@material-ui/core"
// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
// core components
import Button from "components/CustomButtons/Button.js"
import { connect } from 'react-redux';
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import CardBody from "components/Card/CardBody.js"
import { GraphQL } from "../../apiPack";
// Custom
class AddUser extends React.Component {
    constructor() {
        super()
        this.handleChange = this.handleChange.bind(this)
        this.handleToggle = this.handleToggle.bind(this)
        this.submit = this.submit.bind(this)

        this.state = {
            user: "",
            compName: "",
            compDays: 0,
            seats: 0,
            pass: "",
            admin: false
        }
    }



    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          console.log(this.props)
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    handleChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleToggle() {
        this.setState({
            admin: !this.state.admin
        })
    }

    render() {
        const Textfields = {
            width: '100%',
            marginBottom: 10,
        }
        const SubmitButton = {
            marginTop: 20,
            marginBottom: 10,
        }

        return (
            <div>
                <Card style={{ width: 800 }}>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <AccountCircleIcon />
                        </CardIcon>
                        <h4 style={{ color: 'black' }}>Add User</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <div>
                                <TextField style={Textfields} id="compName" onChange={this.handleChange} label="Company Name">{this.state.compName}</TextField>
                            </div>
                            <div>
                                <TextField style={Textfields} id="user" onChange={this.handleChange} label="Name">{this.state.user}</TextField>
                            </div>
                            
                            <div>
                                <TextField style={Textfields} id="compDays" onChange={this.handleChange} label="Subscription Length in Days" type="number">{this.state.compDays}</TextField>
                            </div>
                            <div>
                                <TextField style={Textfields} id="seats" onChange={this.handleChange} label="Seats" type="number">{this.state.seats}</TextField>
                            </div>
                            <div>
                                <TextField style={Textfields} id="compEmail" onChange={this.handleChange} label="Email">{this.state.compEmail}</TextField>
                            </div>
                            <div>
                                <TextField style={Textfields} id="pass" onChange={this.handleChange} label="Password" type="password">{this.state.pass}</TextField>
                            </div>
                            <div>
                                <Button type="submit" onClick={() => {this.submit()}} style={SubmitButton}>Submit</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    submit() {
        console.log('Submit firing!')
        console.log(this.state)
        const queryVariables = gql`
            mutation {
                addCompany(
                    name: "${this.state.compName}",
                    days: "${this.state.compDays}",
                    seats: "${this.state.seats}"
                )
            }`
        this.client.mutate({ mutation: queryVariables }).then((response) => {
            const queryVariables = gql`
                mutation {
                    addCompanyUser(
                        name: "${this.state.user}",
                        password: "${this.state.pass}",
                        email: "${this.state.compEmail}",
                        company: "${response.data.addCompany}",
                        companyAdmin: true
                    )
                }`
            this.client.mutate({ mutation: queryVariables }).then(() => {
                
            })
        })
    }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);