// Defaults
import React from "react";
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
import ReactQuill from 'react-quill';
import jwt from 'jsonwebtoken'
// Material-ui
import { Grid, Typography, List, TextField, IconButton, ListItem, ListItemText, MenuItem, Checkbox, Input, InputLabel, Select, FormControl, Chip } from "@material-ui/core/"
import Autocomplete from '@material-ui/lab/Autocomplete'
// Icons
import MailOutline from "@material-ui/icons/MailOutline";
import withStyles from "@material-ui/core/styles/withStyles";
import { IoIosHelpCircleOutline } from "react-icons/io";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SweetAlert from "react-bootstrap-sweetalert";
import toolTip from "../../assets/css/tooltip-classic.css";
// External Styles
import 'react-quill/dist/quill.snow.css';
// Internal Custom
import AutoFillData from "../../data-packs/Add-Vuln-Data";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import { GraphQL } from "../../apiPack";
import { connect } from 'react-redux';
import Assignment from "@material-ui/icons/Assignment";
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from 'material-table';



const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    ...toolTip,
};
class AddVuln extends React.Component {
    constructor() {
        super()

        this.state = {
            allLoginData: []
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.loggedIn !== undefined) {
          try {
            var decoded = jwt.deocde(this.props.loggedIn)
            this.setState({ updateUUID: decoded.uuid, error: false, profilePicture: decoded.profilePicture, userProfile: decoded.name })
            if (decoded.admin === true) {
              const queryVariables = gql`
                query {
                    audit {
                    name
                    timestamp
                    status
                    }
                }
              `
              this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                console.log(response.data)
                this.setState({
                    allLoginData: response.data.audit
                })
                setTimeout(() => {
                    console.log(this.state.allLoginData)
                }, 5000);
              })
            } else {
                this.props.history.push('/login')
            }
          } catch (e) {
             this.props.history.push('/login')
          };
        } else {
            this.props.history.push('/login')
        }
    }

    render() {
        return (
            <div style={{ maxWidth: "100%" }}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 style={{marginTop: '15px', color: 'black'}}>Previous Login</h4>
                    </CardHeader>
                    <CardBody>
                        <div style={{ maxWidth: "100%" }}>
                            <MaterialTable
                                options={{ pageSizeOptions: [10, 25, 100], pageSize: 10 }}
                                columns={[
                                    { title: "Status", field: "status", width: 80},
                                    { title: "Name", field: "name" },
                                    { title: "Time", field: "timestamp", width: 300, float: 'right' }
                                ]}
                                data={this.state.allLoginData}
                                title=""
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        snackbarEdit: state.snackbarEdit,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
        setSnackbar: (input) => dispatch({ type: 'SNACKBAR', payload: input })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AddVuln));