import React from "react";
import MaterialTable from 'material-table';
import ApolloBoost from 'apollo-boost';
import gql from "graphql-tag";
import { Link } from 'react-router-dom'
// react component for creating dynamic tables
import ReactTable, { useFlexLayout } from "react-table";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import jwt from 'jsonwebtoken'
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Dialog, List, Checkbox, ListItem, Grid, FormControlLabel, Chip, Typography, Divider, Paper, IconButton } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import CloseIcon from '@material-ui/icons/Close';
import { GraphQL } from "../../apiPack";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { tags } from "../../data-packs/Add-Vuln-Data"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import openfilter from '@material-ui/icons/History';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class ViewVuln extends React.Component {
    constructor() {
        super()
        this.confirmpop = this.confirmpop.bind(this)
        this.setpop = this.setpop.bind(this)
        this.closepop = this.closepop.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.filterView = this.filterView.bind(this)
        this.toggleFilter = this.toggleFilter.bind(this)

        this.state = {
            data: [],
            setData: [],
            test: [],
            admin: false,
            filter: [],
            deletePop: false,
            deleteUUID: '',
            searchArray: [],
            tags: [],
            filtered: [],
            filterOptions: [],
            toggleView: false,
            searchArray: []
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.loggedIn !== undefined) {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                this.setState({ updateUUID: decoded.uuid, admin: decoded.admin })
            } catch (e) {
                this.props.history.push('/login')
            };
        } else {
            this.props.history.push('/login')
        }
        const queryVariables = gql`
            query {
                checklist {
                    dkbid,
                    uuid,
                    title,
                    checklistName
                },
            }`

        this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
            const z = this.state.test
            for (var i = 0; i < response.data.checklist.length; i++) {
                z.push({
                    dkbid: response.data.checklist[i].dkbid,
                    uuid: response.data.checklist[i].uuid,
                    name: response.data.checklist[i].title,
                    checklistName: response.data.checklist[i].checklistName
                })
            }
            this.setState({
                test: z.reverse()
            })
        }) 
        setTimeout(() => {
            console.log(this.state)
        }, 10000)
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "15px",
        }
        if (this.state.admin === true) {
            return (
                <div style={{ maxWidth: "100%" }}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                        </CardHeader>   
                        <CardBody>
                            <Grid container>
                                <Grid item xs={12}>  
                                    <div style={{ maxWidth: "100%" }}>
                                        <MaterialTable
                                            options={{ 
                                                pageSizeOptions: [10, 25, 100], 
                                                pageSize: 10,
                                                headerStyle: {
                                                    fontSize: 24 
                                                },
                                            }}
                                            columns={[
                                                { title: "DKB ID", field: "dkbid", render: rowData => <Link to={{ pathname: '/admin/ViewSpecificCheck', aboutProps: { name: rowData.uuid } }}><Button style={{ padding: 0, height: 18, width: 80, marginRight: 4, backgroundColor: '#df2869', color: 'white' }} label={rowData.dkbid}>{rowData.dkbid}</Button></Link> },
                                                { title: "Title", field: "name", render: rowData => <> 
                                                <p style={{fontSize: 18}}>{rowData.name}</p>
                                            </> },
                                                {
                                                    title: "Tags", field: "tags", render: rowData => <>
                                                        <Chip style={{ height: 18, marginRight: 4, backgroundColor: 'red', color: 'white' }} key={rowData.checklistName} name={rowData.checklistName} label={rowData.checklistName} onClick={() => this.filterView(rowData.checklistName)}>{rowData.checklistName}</Chip>
                                                    </>
                                                },
                                                {
                                                    title: "Actions", field: "actions", width: 180, render: rowData => <div>
                                                        <Link to={{ pathname: '/admin/EditSpecificCheck', aboutProps: { name: rowData.uuid } }}><IconButton style={{ marginRight: 10 }} size="small" label="View"><EditIcon style={{ color: ViewVulnColor.infrastructure }} /></IconButton></Link>
                                                        <IconButton size="small" label="Delete" onClick={() => this.setpop(rowData.uuid)}><CloseIcon style={{ color: ViewVulnColor.webApplication }} /></IconButton>
                                                    </div>
                                                },
                                            ]}
                                            data={this.state.searchArray.length === 0 ? this.state.test : this.state.searchArray}
                                            title=""
                                            actions={[
                                                {
                                                  icon: openfilter,
                                                  tooltip: 'Remove Filter',
                                                  isFreeAction: true,
                                                  onClick: () => this.toggleFilter()
                                                }
                                            ]}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </CardBody>
                    </Card>
                    {this.confirmpop()}
                </div>
            );
        } else {
            return (
                <div style={{ maxWidth: "100%" }}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <div style={{ maxWidth: "100%" }}>
                                <MaterialTable
                                    options={{ 
                                        pageSizeOptions: [10, 25, 100], 
                                        pageSize: 10,
                                        headerStyle: {
                                            fontSize: 24 
                                        },
                                    }}
                                    columns={[
                                        { title: "DKB ID", cellStyle: { width: '10%', maxWidth: '10%' }, headerStyle: { width: '10%', maxWidth: '10%'}, field: "dkbid", render: rowData => <Link to={{ pathname: '/admin/ViewSpecificCheck', aboutProps: { name: rowData.uuid } }}><Button style={{ padding: 0, height: 17, width: 80, marginRight: 4, backgroundColor: '#00008b', color: 'white' }} label={rowData.dkbid}>{rowData.dkbid}</Button></Link> },
                                        { title: "Title", cellStyle: { width: '80%', maxWidth: '10%' }, headerStyle: { width: '80%', maxWidth: '10%'}, field: "name", render: rowData => <> 
                                        <p style={{fontSize: 18}}>{rowData.name}</p>
                                    </> },
                                        {
                                            title: "Tags", field: "tags", render: rowData => <>
                                                <Chip style={{ height: 18, marginRight: 4, backgroundColor: 'red', color: 'white' }} key={rowData.checklistName} name={rowData.checklistName} label={rowData.checklistName} onClick={() => this.filterView(rowData.checklistName)}>{rowData.checklistName}</Chip>
                                            </>
                                        },
                                        {
                                            title: "Actions", cellStyle: { width: '10%', maxWidth: '10%' }, headerStyle: { width: '10%', maxWidth: '10%'}, field: "actions", width: 140, render: rowData => <div>
                                                <Link to={{ pathname: '/admin/ViewDetails', aboutProps: { name: rowData.uuid } }}><IconButton size="small" label="View"><InfoRoundedIcon style={{ color: '#127dff' }} /></IconButton></Link>
                                            </div>
                                        },
                                    ]}
                                    data={this.state.searchArray.length === 0 ? this.state.test : this.state.searchArray}
                                    title=""
                                    actions={[
                                        {
                                          icon: openfilter,
                                          tooltip: 'Remove Filter',
                                          isFreeAction: true,
                                          onClick: () => this.toggleFilter()
                                        }
                                    ]}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    filterView(tag) {
        var lm = []
        for (var jp = 0; jp < this.state.test.length; jp++) {
            if (this.state.test[jp].checklistName === tag) {
                lm.push({
                    dkbid: this.state.test[jp].dkbid,
                    uuid: this.state.test[jp].uuid,
                    name: this.state.test[jp].name,
                    checklistName: this.state.test[jp].checklistName
                })
            }
        }
        this.setState({
            searchArray: lm
        })
    }

    toggleFilter() { // Remake this for toggle filter
        this.setState({searchArray: []})
    }

    setpop(uuid) {
        this.setState({
            deleteUUID: uuid,
            deletePop: true
        })
    }

    closepop() {
        this.setState({
            deletePop: false
        })
    }

    confirmpop() {
        if (this.state.deletePop === true) {
            return (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.deleteItem()}
                    onCancel={() => this.closepop()}
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                </SweetAlert>
            );
        }
    }

    deleteItem() {
        const queryVariables = gql`
        mutation {
          delete(
            uuid: "${this.state.deleteUUID}",
          ) 
        }`
        this.client.mutate({ mutation: queryVariables }).then(() => {
            this.setState({
                deleteUUID: '',
                deletePop: false,
                test: []
            })
            this.componentDidMount()
        })
    }


}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVuln);