module.exports = {

    // CVSS Score Color Codes
    Low: '#1C84C6',
    Medium: '#F1C867',
    High: '#F8AC59',
    Critical: '#ED5565',

    gray: '#999',

    // Style Colors
    DefendzaRed: '#E42025',
}
