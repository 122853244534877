// Defaults
import Dashboard from "views/Dashboard/Dashboard.js";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
//Customs
import AddVuln from "views/AddVuln/AddVuln.js"
import ViewDetCheck from "views/ViewCheck/ViewDetCheck.jsx"
import EditCheck from "views/ViewCheck/EditCheck.js"
import ViewCheck from "views/ViewCheck/ViewCheck.js"
import ViewVuln from "views/ViewVuln/ViewVuln.js"
import ViewMeth from "views/ViewMeth/ViewMeth.js"
import Audit from "views/Audit/Audit.js"
import AddMeth from "views/AddVuln/AddMeth.js"
import ViewNotifications from "views/Dashboard/NotificationCenter.js"
import ViewDeleted from "views/ViewVuln/ViewDeleted.js"
import DeepSearch from "views/ViewVuln/DeepSearch.js"
import ListAltIcon from '@material-ui/icons/ListAlt';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import ViewQueue from 'views/ViewVuln/QueueOverView.js'
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Management from "views/Summary/management.js"
import Conclusion from "views/Summary/conclusion.js"
import DeleteIcon from '@material-ui/icons/Delete';
import AddUser from "views/Profile/AddUser.js"
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import EditMeth from "views/ViewMeth/EditMeth";
import EditVuln from "views/ViewVuln/EditVuln.jsx"
import { IoIosBriefcase, IoIosWarning } from "react-icons/io";
import { FaSearchPlus, FaClipboardList } from "react-icons/fa"
import { MdNoteAdd } from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import { AiFillFolderAdd, AiFillFolderOpen } from "react-icons/ai";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    invis: false
  },
  {
    path: "/DeepSearch",
    name: "Deep Search",
    icon: FaSearchPlus,
    component: DeepSearch,
    layout: "/admin",
    invis: false
  },
  {
    collapse: true,
    name: "Vulnerabiltity",
    state: "vulnCollapse",
    icon: IoIosWarning,
    views : [
      {
        path: "/AddVuln",
        name: "Add Vulnerability",
        icon: AddToPhotosIcon,
        component: AddVuln,
        layout: "/admin",
        invis: false
      },
      {
        path: "/ViewVuln",
        name: "View Vulnerability",
        icon: ListAltIcon,
        component: ViewVuln,
        layout: "/admin",
        invis: false
      },
      {
        path: "/EditVuln",
        name: "Edit Vuln",
        component: EditVuln,
        invis: true
      }
    ]
  },
  {
    collapse: true,
    name: "Summary",
    state: "summCollapse",
    icon: IoIosBriefcase,
    views : [
      {
        path: "/management",
        name: "Management",
        icon: DashboardIcon,
        component: Management,
        layout: "/admin",
        invis: false
      },
      {
        path: "/conclusion",
        name: "Conclusion",
        component: Conclusion,
        icon: DashboardIcon,
        layout: "/admin",
        invis: false
      }
    ]
  },
  {
    collapse: true,
    name: "Methodology",
    state: "methCollapse",
    icon: IoIosBriefcase,
    views : [
      {
        path: "/AddMeth",
        name: "Add Methodology",
        icon: MdNoteAdd,
        component: AddMeth,
        layout: "/admin",
        invis: false
      },
      {
        path: "/ViewMeth",
        name: "View Methodology",
        icon: FaClipboardList,
        component: ViewMeth,
        layout: "/admin",
        invis: false
      },
      {
        path: "/EditMeth",
        name: "Edit Methodology",
        component: EditMeth,
        layout: "/admin",
        invis: true
      }
    ]
  },
  {
    collapse: true,
    name: "Checklist",
    state: "checkCollapse",
    icon: GoChecklist,
    views : [
      {
        path: "/ViewChecklist",
        name: "View Checklists",
        icon: AiFillFolderOpen,
        component: ViewCheck,
        layout: "/admin",
        invis: false
      },
      {
        path: "/ViewSpecificCheck",
        name: "View Specific Check",
        component: ViewDetCheck,
        layout: "/admin",
        invis: true
      },
      {
        path: "/Queue",
        name: "View Queue",
        icon: ClearAllIcon,
        component: ViewQueue,
        layout: "/admin",
        invis: true
      },
      {
        path: "/EditSpecificCheck",
        name: "Edit Specific Check",
        component: EditCheck,
        layout: "/admin",
        invis: true
      }
    ]
  },
];
export default dashRoutes;
