import React from "react";
import MaterialTable from 'material-table';
import ApolloBoost from 'apollo-boost';
import gql from "graphql-tag";
import { Link } from 'react-router-dom'
// react component for creating dynamic tables
import ReactTable, { useFlexLayout } from "react-table";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import jwt from 'jsonwebtoken'
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Dialog, List, Checkbox, ListItem, Grid, FormControlLabel, Chip, Typography, Divider, Paper, IconButton } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField"
import Edit from "@material-ui/icons/Edit";
import CloseIcon from '@material-ui/icons/Close';
import { GraphQL } from "../../apiPack";
import Switch from '@material-ui/core/Switch';

class ViewVuln extends React.Component {
    constructor() {
        super()
        this.confirmpop = this.confirmpop.bind(this)
        this.setpop = this.setpop.bind(this)
        this.closepop = this.closepop.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.searchResults = this.searchResults.bind(this)
        this.handleChange = this.handleChange.bind(this)

        this.state = {
            data: [],
            setData: [],
            test: [],
            admin: false,
            filter: [],
            deletePop: false,
            deleteUUID: '',
            searchBar: '',
            switchBool: false
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.loggedIn !== undefined) {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                this.setState({ updateUUID: decoded.uuid, admin: decoded.admin })
            } catch (e) {
                this.props.history.push('/login')
            };
        } else {
            this.props.history.push('/login')
        }
    }

    handleChange() {
        this.setState({
            switchBool: !this.state.switchBool,
            test: []
        })
    }

    searchResults(e) {
        e.preventDefault()
        this.setState({test: []})
        if (this.state.switchBool === false) {  
            const queryVariables = gql`
                query {
                    deepsearch(query: "${this.state.searchBar}") {
                        dkbid,
                        uuid,
                        owner,
                        title,
                        cvss,
                        severity,
                        tag {
                            text
                        },
                    },
                }`
            this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                const z = this.state.test
                console.log(response.data)
                for (var i = 0; i < response.data.deepsearch.length; i++) {
                    var o = []
                    for (var m = 0; m < response.data.deepsearch[i].tag.length; m++) {
                        o.push(response.data.deepsearch[i].tag[m].text)
                    }
                    z.push({
                        dkbid: response.data.deepsearch[i].dkbid,
                        uuid: response.data.deepsearch[i].uuid,
                        name: response.data.deepsearch[i].title,
                        cvss: response.data.deepsearch[i].cvss,
                        severity: response.data.deepsearch[i].severity,
                        tags: o
                    })
                }
                this.setState({
                    test: z.reverse()
                })
            })
        } else {
            console.log('Firing inside methdeep')
            const methdeepsearch = gql`
                query {
                    methdeep(query: "${this.state.searchBar}") {
                        uuid,
                        dkbid,
                        title,
                        tag {
                            text
                        }
                    },
                }`
            this.client.query({ query: methdeepsearch, fetchPolicy: 'no-cache' }).then((response) => {
                console.log(response.data)
                const z = this.state.test
                for (var i = 0; i < response.data.methdeep.length; i++) {
                    var o = []
                    for (var m = 0; m < response.data.methdeep[i].tag.length; m++) {
                        o.push(response.data.methdeep[i].tag[m].text)
                    }
                    z.push({
                        dkbid: response.data.methdeep[i].dkbid,
                        uuid: response.data.methdeep[i].uuid,
                        name: response.data.methdeep[i].title,
                        tags: o
                    })
                }
                this.setState({
                    test: z.reverse()
                })
            })
        }
    }

    searchBar(e) {
        this.setState({searchBar: e.target.value})
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "15px",
        }
        return (
            <div style={{ maxWidth: "100%" }}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <div style={{ maxWidth: "100%" }}>
                            <form style={{width: "100%"}}>
                                <GridContainer>
                                        <GridItem xs={9} sm={8}>
                                            <TextField style={{ width: '100%' }} label="Search" id="searchBar" value={this.state.searchBar} onChange={(e) => this.searchBar(e)}></TextField>
                                        </GridItem>
                                        <GridItem xs={2} sm={3}>
                                            <div style={{marginTop: 10}}>
                                                <label style={{marginRight: 10}}>Vuln</label>
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={this.state.switchBool}
                                                        onChange={this.handleChange}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                    }
                                                    label="Meth"
                                                    labelPosition="right"
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={1} sm={1}>
                                            <Button style={{ width: '100%', backgroundColor: 'red' }} type="submit" label="Search" id="searchBar" value={this.state.searchBar} onClick={(e) => this.searchResults(e)}>Search</Button>
                                        </GridItem>
                                </GridContainer>
                            </form>
                            {this.state.switchBool === false ? <MaterialTable
                                options={{ 
                                    pageSizeOptions: [10, 25, 100], 
                                    pageSize: 10,
                                    search: false,
                                    headerStyle: {
                                        fontSize: 24 
                                    },
                                }}
                                columns={[
                                    { title: "DKB ID", field: "dkbid", render: rowData => <Link to={{ pathname: '/admin/ViewDetails', aboutProps: { name: rowData.uuid } }}><Button style={{ padding: 0, height: 17, width: 80, marginRight: 4, backgroundColor: '#00008b', color: 'white' }} label={rowData.dkbid}>{rowData.dkbid}</Button></Link> },
                                    { title: "Vulnerability", field: "name",render: rowData => <> 
                                    <p style={{fontSize: 18}}>{rowData.name}</p>
                                </> },
                                    {
                                        title: "Tags", field: "tags", render: rowData => <>
                                            {rowData.tags.map((value, index) => {
                                                var x
                                                if (value === 'Web Application') {
                                                    x = ViewVulnColor.webApplication
                                                } else if (value === "Mobile Application") {
                                                    x = ViewVulnColor.mobileApplication
                                                } else if (value === "Infrastructure") {
                                                    x = ViewVulnColor.infrastructure
                                                } else if (value === "Wireless") {
                                                    x = ViewVulnColor.wireless
                                                } else if (value === "Code Review") {
                                                    x = ViewVulnColor.codeReview
                                                } else if (value === "Build Review") {
                                                    x = ViewVulnColor.buildReview
                                                } else if (value === "Cloud Security") {
                                                    x = ViewVulnColor.cloudSecurity
                                                } else if (value === "Physical Security") {
                                                    x = ViewVulnColor.physicalSecurity
                                                } else if (value === "Red Team") {
                                                    x = ViewVulnColor.redTeam
                                                }
                                                return (
                                                    <Chip style={{ height: 17, marginRight: 4, backgroundColor: x, color: 'white' }} label={value}>{value}</Chip>
                                                );
                                            })}
                                        </>
                                    },
                                    { title: "CVSS", field: "cvss", width: 20 },
                                    { title: "Severity", field: "severity", width: 20 },
                                ]}
                                data={this.state.test}
                                title=""
                                components={{
                                    Container: props => props.children
                                }}
                            />: <MaterialTable
                            options={{ 
                                pageSizeOptions: [10, 25, 100], 
                                pageSize: 10,
                                search: false,
                                headerStyle: {
                                    fontSize: 24 
                                },
                            }}
                            columns={[
                                { title: "DKB ID", field: "dkbid", render: rowData => <Link to={{ pathname: '/admin/ViewDetails', aboutProps: { name: rowData.uuid } }}><Button style={{ padding: 0, height: 17, width: 80, marginRight: 4, backgroundColor: '#00008b', color: 'white' }} label={rowData.dkbid}>{rowData.dkbid}</Button></Link> },
                                { title: "Vulnerability", field: "name",render: rowData => <> 
                                <p style={{fontSize: 18}}>{rowData.name}</p>
                            </> },
                                {
                                    title: "Tags", field: "tags", render: rowData => <>
                                        {rowData.tags.map((value, index) => {
                                            var x
                                            if (value === 'Web Application') {
                                                x = ViewVulnColor.webApplication
                                            } else if (value === "Mobile Application") {
                                                x = ViewVulnColor.mobileApplication
                                            } else if (value === "Infrastructure") {
                                                x = ViewVulnColor.infrastructure
                                            } else if (value === "Wireless") {
                                                x = ViewVulnColor.wireless
                                            } else if (value === "Code Review") {
                                                x = ViewVulnColor.codeReview
                                            } else if (value === "Build Review") {
                                                x = ViewVulnColor.buildReview
                                            } else if (value === "Cloud Security") {
                                                x = ViewVulnColor.cloudSecurity
                                            } else if (value === "Physical Security") {
                                                x = ViewVulnColor.physicalSecurity
                                            } else if (value === "Red Team") {
                                                x = ViewVulnColor.redTeam
                                            }
                                            return (
                                                <Chip style={{ height: 17, marginRight: 4, backgroundColor: x, color: 'white' }} label={value}>{value}</Chip>
                                            );
                                        })}
                                    </>
                                },
                            ]}
                            data={this.state.test}
                            title=""
                            components={{
                                Container: props => props.children
                            }}
                        />}
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    setpop(uuid) {
        this.setState({
            deleteUUID: uuid,
            deletePop: true
        })
    }

    closepop() {
        this.setState({
            deletePop: false
        })
    }

    confirmpop() {
        if (this.state.deletePop === true) {
            return (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.deleteItem()}
                    onCancel={() => this.closepop()}
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                </SweetAlert>
            );
        }
    }

    deleteItem() {
        const queryVariables = gql`
        mutation {
          delete(
            uuid: "${this.state.deleteUUID}",
          ) 
        }`
        this.client.mutate({ mutation: queryVariables }).then(() => {
            this.setState({
                deleteUUID: '',
                deletePop: false,
                test: []
            })
            this.componentDidMount()
        })
    }


}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVuln);