// Defaults
import React from "react";
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
import ReactQuill from 'react-quill';
import jwt from 'jsonwebtoken'
// Material-ui
import { Grid, Typography, List, TextField, IconButton, ListItem, ListItemText, MenuItem, Checkbox, Input, InputLabel, Select, FormControl, Chip } from "@material-ui/core/"
import Autocomplete from '@material-ui/lab/Autocomplete'
// Icons
import MailOutline from "@material-ui/icons/MailOutline";
import withStyles from "@material-ui/core/styles/withStyles";
import { IoIosHelpCircleOutline } from "react-icons/io";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SweetAlert from "react-bootstrap-sweetalert";
import toolTip from "../../assets/css/tooltip-classic.css";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ReactHtmlParser from 'react-html-parser';
import Paper from '@material-ui/core/Paper';
// External Styles
import 'react-quill/dist/quill.snow.css';
// Internal Custom
import AutoFillData from "../../data-packs/Add-Vuln-Data";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import { GraphQL } from "../../apiPack";
import { connect } from 'react-redux';

const style = {
    infoText: {
      fontWeight: "300",
      margin: "10px 0 30px",
      textAlign: "center"
    },
    ...styles
  };

class AddVuln extends React.Component {
    constructor() {
        super()
        this.handleFinding = this.handleFinding.bind(this)
        this.handleTechDetails = this.handleTechDetails.bind(this)
        this.handleRemediation = this.handleRemediation.bind(this)
        this.handleResources = this.handleResources.bind(this)
        this.handleChange4 = this.handleChange4.bind(this)
        this.handleChange5 = this.handleChange5.bind(this)
        this.handleChange7 = this.handleChange7.bind(this)
        this.handleChange8 = this.handleChange8.bind(this)
        this.handleChange99 = this.handleChange99.bind(this)
        this.hideAlert = this.hideAlert.bind(this)
        this.popAlert = this.popAlert.bind(this)
        this.popFail = this.popFail.bind(this)
        this.onTagsChange = this.onTagsChange.bind(this)
        this.preview = this.preview.bind(this)
        this.handleClose = this.handleClose.bind(this)

        this.state = {
            cvss: '',
            title: '',
            cwe2: '',

            previewFind: '',
            previewTech: '',
            previewRem: '',
            finding: '',
            technical: '',
            remediation: '',
            resources: '',
            dkbid: '',
            version: '',
            justification: '',
            previewWindow: false,
            similarIssueInput: '',
            company: '',
            cwe: [],
            top100Films: [],
            autocomplete: [],
            tags: [],
            controls: [],
            uuid: '',
            admin: '',
            updateUUID: '',
            submitError: '',

            setAlert: false,
            setFail: false,
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        console.log('Firing inside EditVuln')
        if (this.props.location.aboutProps === undefined) {
            console.log('this.props is empty so redirecting to /login!')
            this.props.history.push('/login')
        } else {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                this.setState({ updateUUID: decoded, admin: decoded.admin, uuid: decoded.uuid })
                const queryVariables = gql`
                    query {
                        finding (query: "${this.props.location.aboutProps.name}") {
                            uuid,
                            dkbid
                            owner,
                            title,
                            cvss,
                            severity,
                            cwe {text},
                            cwe2,
                            tag {text},
                            control {text},
                            finding,
                            resources,
                            techDetails,
                            remediation,
                            version,
                            comment {
                                content, 
                                owner {uuid}
                            }
                            similar {
                                uuid
                                dkbid
                                title
                                otheruuid
                            }
                        },
                        company (query: "${decoded.company}") {
                            name
                        }
                    }`
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    var x = []
                    for (var i = 0; i < response.data.finding[0].tag.length; i++) {
                        x.push(response.data.finding[0].tag[i].text)
                    }
                    var y = []
                    for (var i = 0; i < response.data.finding[0].control.length; i++) {
                        y.push(response.data.finding[0].control[i].text)
                    }
                    var z = []
                    for (var i = 0; i < response.data.finding[0].cwe.length; i++) {
                        z.push(response.data.finding[0].cwe[i].text)
                    }
                    console.log(response.data.company[0].name)
                    this.setState({
                        all: response.data.finding[0],
                        cvss: response.data.finding[0].cvss,
                        title: response.data.finding[0].title,
                        cwe2: response.data.finding[0].cwe2,
                        dkbid: response.data.finding[0].dkbid,
                        version: response.data.finding[0].version,
                        company: response.data.company[0].name,
                        tags: x,
                        controls: y,
                        cwe: z,
                        finding: Base64.decode(response.data.finding[0].finding),
                        technical: Base64.decode(response.data.finding[0].techDetails),
                        remediation: Base64.decode(response.data.finding[0].remediation),
                        resources: Base64.decode(response.data.finding[0].resources),
                        comments: response.data.finding[0].comment.reverse(),
                        autocomplete: response.data.finding[0].similar
                    })
                })
                const secondQuery = gql`
                    query {
                        finding{
                            dkbid
                            title
                            uuid
                        }
                    }`
                this.client.query({ query: secondQuery, fetchPolicy: 'no-cache' }).then((response) => {
                    if (this.state.autocomplete.length !== 0) {
                        this.setState({ top100Films: response.data.finding })
                        
                    } else {
                        this.setState({ top100Films: response.data.finding, autocomplete: [response.data.finding[0]] })
                        setTimeout(() => {
                            this.setState({autocomplete: []})
                        }, 15);
                    }
                })
            } catch (e) {
                console.log(e)
                console.log('inside catch so redirecting to /login!')
                this.props.history.push('/login')
            }
        }
    }

    handleFinding(value) {
        this.setState({
            finding: value
        })
    }
    handleTechDetails(value) {
        this.setState({
            technical: value
        })
    }
    handleRemediation(value) {
        this.setState({
            remediation: value
        })
    }
    handleResources(value) {
        this.setState({
            resources: value
        })
    }
    handleChange99(e) {
        this.setState({
            similarIssueInput: e.target.value
        })
        setTimeout(() => {
            console.log(this.state.similarIssueInput)
        }, 50)
    }
    handleChange4(e) {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleChange5(e) {
        this.setState({
            tags: e.target.value
        })
    }
    handleChange7(e) {
        this.setState({
            controls: e.target.value
        })
    }
    handleChange8(e) {
        this.setState({
            cwe: e.target.value
        })
    }

    render() {
        const modules = {
            clipboard: { matchVisual: false },
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image', 'video', 'table'],
                ['clean']
            ],
        }
        const modules1 = {
            clipboard: { matchVisual: false },
            toolbar: ['link']
        }
        const formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'color', 'background',
            'link', 'image', 'video'
        ]
        const formats1 = [
            'link'
        ]
        const headerTop = {
            ...cardTitle,
            color: '#737373',
            fontSize: 20
        }
        const headerLower = {
            ...cardTitle,
            color: '#737373',
            fontSize: 20,
            marginTop: 30,
        }
        const cardIconTitle = {
            ...cardTitle,
            fontSize: 22,
            color: '#737373',
            marginTop: "15px",
            marginBottom: "0px"
        }
        const chips = {
            marginRight: 4,
            marginLeft: 4,
            height: 17,
            backgroundColor: "#e91e63",
            color: 'white'
        }

        return (
            <div>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose"><MailOutline /></CardIcon>
                        <h4 style={cardIconTitle}>Edit Vulnerability</h4>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <Grid style={{ marginBottom: 10 }} container spacing={2}>
                                <Grid item xs={1}>
                                    <TextField label="CVSS" style={{ width: '100%' }} id="cvss" value={this.state.cvss} type="String" onChange={this.handleChange4}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl style={{ width: '100%', marginBottom: 20 }}>
                                        <InputLabel id="controls">OWASP</InputLabel>
                                        <Select
                                            value={this.state.cwe}
                                            onChange={this.handleChange8}
                                            renderValue={() => (<div>{this.state.cwe.map(value => (<Chip style={chips} key={value} label={value} />))}</div>)}
                                            multiple
                                        >
                                            {AutoFillData.cwe.map((tag1, index) => (
                                                <MenuItem key={tag1.value} value={tag1.display}>{tag1.display}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField label="CWE-ID" id="cwe2" style={{ width: '100%' }} value={this.state.cwe2} onChange={this.handleChange4}></TextField>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField style={{ width: '100%' }} label="Title" id="title" value={this.state.title} onChange={this.handleChange4}></TextField>
                                </Grid>
                            </Grid>
                            {this.state.admin === false ? <Grid container spacing={2} style={{ marginBottom: 20 }}>
                                <Grid item xs={12}>
                                    <TextField style={{ width: '100%' }} label="Justification" placeholder="Why should we accept your change?" id="justification" value={this.state.justification} onChange={this.handleChange4}></TextField>
                                </Grid>
                            </Grid> : null}
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%', marginBottom: 20 }}>
                                        <InputLabel id="tags">Tags</InputLabel>
                                        <Select
                                            id="tags"
                                            value={this.state.tags}
                                            onChange={this.handleChange5}
                                            renderValue={() => (<>
                                                {this.state.tags.map((value, index) => {
                                                    var x
                                                    if (value === 'Web Application') {
                                                        x = ViewVulnColor.webApplication
                                                    } else if (value === "Mobile Application") {
                                                        x = ViewVulnColor.mobileApplication
                                                    } else if (value === "Infrastructure") {
                                                        x = ViewVulnColor.infrastructure
                                                    } else if (value === "Wireless") {
                                                        x = ViewVulnColor.wireless
                                                    } else if (value === "Code Review") {
                                                        x = ViewVulnColor.codeReview
                                                    } else if (value === "Build Review") {
                                                        x = ViewVulnColor.buildReview
                                                    } else if (value === "Cloud Security") {
                                                        x = ViewVulnColor.cloudSecurity
                                                    } else if (value === "Physical Security") {
                                                        x = ViewVulnColor.physicalSecurity
                                                    } else if (value === "Red Team") {
                                                        x = ViewVulnColor.redTeam
                                                    }
                                                    return (
                                                        <Chip style={{ marginRight: 4, marginLeft: 4, height: 17, backgroundColor: x, color: 'white' }} label={value}>{value}</Chip>
                                                    );
                                                })}
                                            </>)}
                                            multiple>
                                            {AutoFillData.tags.map((tag1, index) => (
                                                <MenuItem key={tag1} value={tag1}>{tag1}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%', marginBottom: 20 }}>
                                        <InputLabel id="controls">Controls Category</InputLabel>
                                        <Select
                                            id="controls"
                                            value={this.state.controls}
                                            onChange={this.handleChange7}
                                            renderValue={() => (<div>{this.state.controls.map(value => (<Chip style={chips} key={value} label={value} />))}</div>)}
                                            multiple>
                                            {AutoFillData.controls.map((tag1, index) => (
                                                <MenuItem key={tag1} value={tag1}>{tag1}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Typography style={headerTop}>Finding</Typography>
                                    <ReactQuill style={{ marginTop: 10 }} id="finding" value={this.state.finding} onChange={this.handleFinding} modules={modules} formats={formats} />
                                    <Typography style={headerLower}>Technical Details</Typography>
                                    <ReactQuill style={{ marginTop: 10 }} id="technical" value={this.state.technical} onChange={this.handleTechDetails} modules={modules} formats={formats} />
                                    <Typography style={headerLower}>Remediation</Typography>
                                    <ReactQuill style={{ marginTop: 10 }} id="remediation" value={this.state.remediation} onChange={this.handleRemediation} modules={modules} formats={formats} />

                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {this.state.top100Films.length !== 0 ? <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        id="fixed-tags-demo"
                                        inputValue={this.state.similarIssueInput}
                                        disableCloseOnSelect
                                        options={this.state.top100Films}
                                        getOptionLabel={option => option.title}
                                        clearOnEscape="false"
                                        defaultValue={this.state.autocomplete}
                                        value={this.state.autocomplete}
                                        onChange={this.onTagsChange}
                                        style={{ width: '100%' }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip label={option.dkbid} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={params => (
                                            <TextField {...params} style={{ width: '100%' }} label="Similar Issues" onChange={this.handleChange99} variant="outlined" placeholder=" " />
                                        )}
                                    /> : null}
                                    <Typography style={headerTop}>Resources</Typography>
                                    <ReactQuill style={{ marginTop: 10, height: 100, marginBottom: 80 }} id="resources" value={this.state.resources} onChange={this.handleResources} modules={modules1} formats={formats1} />
                                </Grid>
                            </Grid>
                            <div style={{ float: 'left', marginRight: 65 }}>
                            <span className={`tooltip tooltip-effect-5`}>
                                <span className="tooltip-item">
                                    <IoIosHelpCircleOutline style={{ fontSize: 24, float: 'left', marginTop: 30 }} />
                                </span>
                                <span className="tooltip-content clearfix">
                                    <span className="tooltip-text">
                                    <strong>CVSS</strong> - Please use CVSS v3 calculator to calculate the score. No random numbers here please to save embarrassment <br /><br />
                                    <strong>Title</strong> - Title should explain in less than 10 words what the finding is about. <br /><br />
                                    <strong>CWE</strong> / OWASP / WASC  - Please assign the appropriate industry standard against the issue being added to the database. <br /><br />
                                    <strong>Issue</strong> Category - Assign a right category for the new vulnerability. This could be more than one if an issue is shared across different domains. <br /><br />
                                    <strong>Finding</strong> - Finding should provide an overview of the issue in 3 to 4 sentences only. This should detail what has been identified, what is the impact on the environment tested, how it affects CIA triad and finally, if any mitigation is in place. <br /><br />
                                    <strong>Technical</strong> Details - Provide as much detail as possible including screenshot and script commands used to identify a vulnerability and exploit it. this.Client should be able to read and replicate themselves. <br /><br />
                                    <strong>Remediation</strong> - Please keep the remediation as close as possible to the this.clients environment. Going to the lengths of providing commands / code to patch the issue gets extra brownie points. <br /><br />
                                    <strong>Resources</strong> - To use this, add the title name first in the box, click on the link icon and add the hyperlink/URL there. Hit enter. This will create the link to go in the References section of the final report. <br /><br />
                                    </span>
                                </span>
                            </span>
                            </div>
                            <Button style={{ marginTop: 20, backgroundColor: AutoFillData.submitButton, marginRight: 10, float: 'right' }} onClick={() => this.submit()}>Submit</Button>
                            <Button style={{ marginTop: 20, backgroundColor: AutoFillData.submitButton, float: 'right' }} onClick={() => this.preview()}>Preview</Button>
                        </form>
                    </CardBody>
                </Card >
                <Dialog
                    open={this.state.previewWindow}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth = {'lg'}
                    fullWidth="true"
                >
                    <DialogTitle id="alert-dialog-title">{"Preview"}</DialogTitle>
                    <DialogContent>
                        <Paper elevation={0} >
                        <Typography style={cardIconTitle} gutterBottom>Findings</Typography>
                        <Divider />
                        <div style={{marginLeft: 10, marginRight: 10, marginTop: 6, marginBottom: 24}}>
                            <Typography style={{fontSize: 14, color: '#404040'}} varient="caption" gutterBottom>{ReactHtmlParser(this.state.previewFind)}</Typography>
                        </div>
                        <br/>
                        <Typography style={cardIconTitle} gutterBottom>Technical Details</Typography>
                        <Divider />
                        <div style={{marginLeft: 10, marginRight: 10, marginTop: 6, marginBottom: 24}}>
                            <Typography style={{fontSize: 14, color: '#404040'}} varient="caption" gutterBottom>{ReactHtmlParser(this.state.previewTech)}</Typography>
                        </div> 
                        <br/>
                        <Typography style={cardIconTitle} gutterBottom>Remediation</Typography>
                        <Divider />
                        <div style={{marginLeft: 10, marginRight: 10, marginTop: 6, marginBottom: 24}}>
                            <Typography style={{fontSize: 14, color: '#404040'}} varient="caption" gutterBottom>{ReactHtmlParser(this.state.previewRem)}</Typography>
                        </div> 
                        </Paper>
                    </DialogContent>
                </Dialog>
                {this.popFail()}
            </div>
        );
    }

    onTagsChange = (event, values) => {
        this.setState({
            autocomplete: values
        });
    }

    handleClose() {
        this.setState({
          previewWindow: false
        })
      }
    
    preview() {
        console.log('Firing insidef Preview')
        var placeFinder = this.state.finding.split('#{company}#').join(this.state.company)
        var placeTech = this.state.technical.split('#{company}#').join(this.state.company)
        var placeRemed = this.state.remediation.split('#{company}#').join(this.state.company)
        this.setState({
            previewWindow: true,
            previewFind: placeFinder,
            previewTech: placeTech,
            previewRem: placeRemed
        })
        setTimeout(() => {
            console.log(this.state.previewWindow)
        }, 5000);
    }    

    submit() {
        console.log('Submit button firing')
        if (this.state.cvss <= 0 || this.state.cvss > 10) {
            this.setState({ setFail: true, submitError: 'CVSS is missing or incorrect!' })
        } else if (this.state.title === "") {
            this.setState({ setFail: true, submitError: 'Title is missing!' })
        } else if (this.state.justification === "" && this.state.admin === false) {
            this.setState({ setFail: true, submitError: 'Justification is missing!' })
        } else {
            console.log('Passed the state checks')
            var v
            if (this.state.cvss >= 0 && this.state.cvss < 4) {
                v = 'Low'
            } else if (this.state.cvss >= 4 && this.state.cvss < 7) {
                v = 'Medium'
            } else if (this.state.cvss >= 7 && this.state.cvss < 9) {
                v = 'High'
            } else {
                v = 'Critical'
            }
            const resetDB = gql`
                mutation {
                    resetCwe(
                        findingUUID: "${this.props.location.aboutProps.name}"
                    )
                    resetControl(
                        findingUUID: "${this.props.location.aboutProps.name}"
                    )
                    resetTag(
                        findingUUID: "${this.props.location.aboutProps.name}"
                    )
                    resetSimilar(
                        findinguuid: "${this.props.location.aboutProps.name}"
                    )
                }
            `

            this.client.mutate({ mutation: resetDB }).then(() => { })
            for (var u = 0; u < this.state.tags.length; u++) {
                const queryVariables = gql`
                mutation {
                    addTag(
                        findingUUID: "${this.props.location.aboutProps.name}",
                        text: "${this.state.tags[u]}"
                    )
                    {cvss}
                }`
                this.client.mutate({ mutation: queryVariables }).then(() => { })
            }
            for (var ko = 0; ko < this.state.controls.length; ko++) {
                const addControlsMutation = gql`
                mutation {
                    addControl(
                        findingUUID: "${this.props.location.aboutProps.name}",
                        text: "${this.state.controls[ko]}"
                    )
                }`
                this.client.mutate({ mutation: addControlsMutation }).then(() => { })
            }
            for (var uj = 0; uj < this.state.autocomplete.length; uj++) {
                const addControlsMutation = gql`
                mutation {
                    addSimilar(
                        findinguuid: "${this.props.location.aboutProps.name}",
                        dkbid: "${this.state.autocomplete[uj].dkbid}",
                        title: "${this.state.autocomplete[uj].title}",
                        otheruuid: "${this.state.autocomplete[uj].uuid}"
                    )
                }`
                this.client.mutate({ mutation: addControlsMutation }).then(() => { })
            }
            for (var lo = 0; lo < this.state.cwe.length; lo++) {
                switch (this.state.cwe[lo]) {
                  case "A-01 Injection":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A1-Injection.html"
                    break;
                  case "A-02 Broken Authentication":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A2-Broken_Authentication.html"
                    break;
                  case "A-03 Sensitive Data Exposure":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A3-Sensitive_Data_Exposure.html"
                    break;
                  case "A-04 XML External Entities (XXE)":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A4-XML_External_Entities_(XXE).html"
                    break;
                  case "A-05 Broken Access control":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A5-Broken_Access_Control.html"
                    break;
                  case "A-06 Security misconfigurations":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A6-Security_Misconfiguration.html"
                    break;
                  case "A-07 Cross Site Scripting (XSS)":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A7-Cross-Site_Scripting_(XSS).html"
                    break;
                  case "A-08 Insecure Deserialization":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A8-Insecure_Deserialization.html"
                    break;
                  case "A-09 Using Components with known vulnerabilities":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A9-Using_Components_with_Known_Vulnerabilities.html"
                    break;
                  case "A-10 Insufficient logging and monitoring":
                    var foundURL = "https://owasp.org/www-project-top-ten/OWASP_Top_Ten_2017/Top_10-2017_A10-Insufficient_Logging%252526Monitoring.html"
                    break;
                }
                const addCweMutation = gql`
                  mutation {
                    addCwe(
                      findingUUID: "${this.props.location.aboutProps.name}",
                      text: "${this.state.cwe[lo]}",
                      url: "${foundURL}",
                    )
                  }`
                this.client.mutate({ mutation: addCweMutation }).then(() => {})
            }
            console.log('Before Admin Check')
            if (this.state.admin === true) {
                console.log('Inside Admin check true')
                const queryVariables = gql
                    `
                    mutation {
                        adminEdit(
                            owner: "${this.state.uuid}",
                            uuid: "${this.props.location.aboutProps.name}",
                            cvss: "${this.state.cvss}",
                            title: "${this.state.title}",
                            finding: "${Base64.encode(this.state.finding)}",
                            techDetails: "${Base64.encode(this.state.technical)}",
                            remediation: "${Base64.encode(this.state.remediation)}",
                            resources: "${Base64.encode(this.state.resources)}",
                            version: "${this.state.version}",
                            severity: "${v}",
                            cwe2: "${this.state.cwe2}"
                        )
                        {cvss}
                    }
                `

                this.client.mutate({ mutation: queryVariables }).then(() => {
                    this.props.setSnackbar(true)
                    this.props.history.push({
                        pathname: '/admin/ViewDetails',
                        aboutProps: {name: this.props.location.aboutProps.name}
                    })
                })
            } else {
                console.log('Inside admin check false')
                const queryVariables = gql`
                    mutation {
                        editFinding(
                            owner: "${this.state.uuid}",
                            dkbid: "${this.state.dkbid}",
                            uuid: "${this.props.location.aboutProps.name}",
                            cvss: "${this.state.cvss}",
                            title: "${this.state.title}",
                            finding: "${Base64.encode(this.state.finding)}",
                            techDetails: "${Base64.encode(this.state.technical)}",
                            remediation: "${Base64.encode(this.state.remediation)}",
                            resources: "${Base64.encode(this.state.resources)}",
                            version: "${this.state.version}",
                            severity: "${v}",
                            justification: "${this.state.justification}",
                            cwe2: "${this.state.cwe2}"
                        ) 
                        {cvss}
                    }
                `

                this.client.mutate({ mutation: queryVariables }).then(() => {
                    this.setState({
                        cvss: '',
                        title: '',
                        cwe2: '',

                        finding: '',
                        technical: '',
                        remediation: '',
                        resources: '',
                        dkbid: '',
                        version: '',
                        justification: '',

                        cwe: [],
                        top100Films: [],
                        autocomplete: [],
                        tags: [],
                        controls: [],
                        uuid: '',
                        admin: '',
                        updateUUID: '',
                        submitError: '',

                        setAlert: false,
                        setFail: false,
                    })
                    this.props.setSnackbar(true)    
                    this.props.history.push('/admin/dashboard')
                })
            }
        }
    }

    hideAlert() {
        this.setState({
            setAlert: false,
            setFail: false,
            setLink: false

        })
    }

    popAlert() {
        if (this.state.setAlert === true) {
            return (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vulnerability Edited!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    btnSize="sm"
                    confirmBtnBsStyle="primary"
                >
                </SweetAlert>
            );
        }
    }

    popFail() {
        if (this.state.setFail === true) {
            return (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.submitError}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    btnSize="sm"
                    confirmBtnBsStyle="primary"
                >
                </SweetAlert>
            );
        }
    }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        snackbarEdit: state.snackbarEdit,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
        setSnackbar: (input) => dispatch({ type: 'SNACKBAR', payload: input })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AddVuln));