module.exports = {

    // Action Icon Button Colors
    info: '#4db8ff',
    edit: '#66ff99',
    delete: '#ff6666',

    // Tag Colors
    webApplication: '#6DCECE',
    mobileApplication: '#FF8D47',
    infrastructure: '#7FB8FF',
    wireless: '#A6DB3D',
    codeReview: '#FF89F1',
    buildReview: '#CC6EC1',
    cloudSecurity: '#FF42A6',
    physicalSecurity: '#39CE7F',
    redTeam: '#FF4953',

}
