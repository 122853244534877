import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Check from 'mdi-material-ui/Check';
import Close from 'mdi-material-ui/Close';
import Button from '@material-ui/core/Button';
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js";
import Face from "@material-ui/icons/Face";
import Icon from "@material-ui/core/Icon";
import gql from 'graphql-tag';
import ApolloBoost from 'apollo-boost';
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import Warning from "@material-ui/icons/Warning";
import jwt from 'jsonwebtoken'
import { Base64 } from 'js-base64';
import { GraphQL } from "../../apiPack";

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...styles
};

class Login extends React.Component {
    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            errorFlag: false,
            errorMessage: ''
        }
        this.onChangeStringInput = this.onChangeStringInput.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ backgroundColor: '#535353', height: '100vh', display: 'flex', justifyContent: 'center' }}>
                <link href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet" />
                <br /><br /><br /><br /><br />
                <Card xs={12} sm={8} md={4} style={{ maxWidth: '300px', maxHeight: '320px', marginTop: 200 }}>
                    <form>
                        <CardHeader
                            color="rose"
                        >
                            <h4 style={{ textAlign: 'center' }}>Log in</h4>
                        </CardHeader>
                        <CardBody>
                            <FormControl style={{ width: 250, justifyContent: 'left' }}>
                                <InputLabel htmlFor="username">Username</InputLabel>
                                <Input
                                    name="username"
                                    id="username"
                                    type='text'
                                    onChange={this.onChangeStringInput}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Face style={{ color: '#757575' }} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <br /><br />
                            <FormControl style={{ width: 250, justifyContent: 'left' }}>
                                <InputLabel htmlFor="adornment-password">Password</InputLabel>
                                <Input
                                    name="password"
                                    id="adornment-password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.onChangeStringInput}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Icon style={{ color: '#757575' }}>
                                                lock_outline
                                    </Icon>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </CardBody>
                        <CardFooter style={{ marginTop: 50 }}>
                            <Button color="inherit" type="submit" variant="contained" simple size="lg" block style={{ width: 300 }} onClick={(e) => this.onSubmit(e)}>Let{"'"}s Go</Button>
                        </CardFooter>
                    </form>
                </Card>
                <Snackbar
                    place="br"
                    color="danger"
                    icon={Warning}
                    message="Invalid Username or Password!"
                    open={this.state.errorFlag}
                    closeNotification={() => this.setState({ errorFlag: false })}
                    close
                />
            </div>
        )
    }

    onChangeStringInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    // Normal Login Submit
    onSubmit(e) { // On submit, post to the authentication page and if http code 200 then login was successful.
        e.preventDefault()
        const deleteVuln = gql`
            mutation {
                authentication(
                    username: "${this.state.username}", 
                    password: "${this.state.password}"
                )
            }
        `

        this.client.mutate({
            mutation: deleteVuln
        }).then((response) => {
            console.log(response)
            if (response.data.authentication === 'Error!') {
                this.setState({
                    errorFlag: true,
                })
                setTimeout(() => {
                    this.setState({
                        errorFlag: false
                    })
                }, 2500);
            } else {
                try {
                    var decoded = jwt.decode(response.data.authentication)
                    this.props.setAuth(response.data.authentication)
                    this.props.history.push('/admin/dashboard')
                } catch (e) {
                    this.props.history.push('/login')
                };
            }
        })
    }

    // Use this to migrate the data from all base64 to clearText. Needed for deep search
    // onSubmit(e) {
    //     e.preventDefault()
    //     const findAllCurrent = gql`
    //         query {
    //             finding {
    //                 uuid    
    //                 title
    //                 finding
    //                 techDetails
    //                 remediation
    //             }
    //         }
    //     `

    //     client.query({ query: findAllCurrent, fetchPolicy: 'no-cache' }).then((response) => {
    //         console.log(response.data.finding)
    //         for (var kl = 0; kl < response.data.finding.length; kl++) {
    //             const updateExistingFinding = gql`
    //                 mutation {
    //                     retroClearText(
    //                         uuid: "${response.data.finding[kl].uuid}",
    //                         finding: "${response.data.finding[kl].finding}",
    //                         tech: "${response.data.finding[kl].techDetails}",
    //                         remediation: "${response.data.finding[kl].remediation}"
    //                     )
    //                 }
    //             `
    //             console.log(response.data.finding[kl].uuid)
    //             client.query({ query: updateExistingFinding, fetchPolicy: 'no-cache' }).then((response) => {
    //                 console.log('Fired!')
    //             })
    //         }
    //     })
    // }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        auth: state.auth,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPubKey: (payload) => dispatch({ type: 'UPDATEPUBKEY', payload: payload }),
        setAuth: (payload) => dispatch({ type: 'SET', payload: payload }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Login))
