import React from "react";
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
import moment from 'moment';
// @material-ui/core components
import { Grid, Typography, TextField, IconButton, ListItem, Paper, Divider } from "@material-ui/core/"
import Avatar from '@material-ui/icons/AccountBox';
import { connect } from 'react-redux';
import MatLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import ArrowForwardIcon from '@material-ui/icons/ArrowRight';
import ArrowBackIcon from '@material-ui/icons/ArrowLeft';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NavPills from "components/NavPills/MethPills.js";
import withStyles from "@material-ui/core/styles/withStyles";
import jwt from 'jsonwebtoken'
import ReactHtmlParser from 'react-html-parser';
// core components
import Button from "components/CustomButtons/Button.js";
import CommentButton from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import { ImageURL } from "../../apiPack";
import { GraphQL } from "../../apiPack";
import Delete from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import isUrl from "is-url"

const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};

class ViewDetails extends React.Component {
    constructor() {
        super()
        this.postComment = this.postComment.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.deleteComment = this.deleteComment.bind(this)
        this.postNote = this.postNote.bind(this)
        this.deleteNote = this.deleteNote.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.rerenderForSimilar = this.rerenderForSimilar.bind(this)

        this.state = {
            dkbid: '',
            cvss: '',
            nButton: '',
            lButton: '',
            uuid: '',
            comments: [],
            controls: [],
            tags: [],
            cwe: [],
            all: {},
            finding: {},
            techDetails: {},
            remediation: {},
            resources: {},
            versions: [],
            selected: '',
            notes: [],  
            value: "",
            admin: false,
            updateUUID: {},
            versionHistory: false,
            timestamp: '',
            similarTab: [],
            parsedCWE: [],
            emptyAmount: []
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
              authorization: token ? `${token}` : '',
              "X-Frame-Options": "DENY",
              "X-Content-Type-Options": "nosniff",
              "X-XSS-Protection": 1,
              "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.location.aboutProps === undefined) {
            this.props.history.push('/admin/ViewVuln')
        } else if (this.props.loggedIn !== undefined) {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                this.setState({ updateUUID: decoded, admin: decoded.admin, uuid: decoded.uuid, selected: this.props.location.aboutProps.name })
                console.log(this.props.location.aboutProps.name)
                const queryVariables = gql`
                query {
                    methodology (query: "${this.props.location.aboutProps.name}") {
                        dkbid,
                        title,
                        uuid,
                        mainText,
                        resources,
                        timestamp,
                        comment {
                            uuid,
                            content, 
                            timestamp,
                            name,
                            owner{profilePicture}
                        }
                        similar {
                            uuid
                            dkbid
                            title
                            otheruuid
                        }
                        note {
                            uuid,
                            content, 
                            timestamp,
                            name,
                            owner{profilePicture}
                        }
                        tag {
                            text
                        }
                        versions {
                            owner{ 
                                name
                            }
                            timestamp
                            version
                        }
                        version
                    },
                }`
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    console.log(response.data)
                    console.log(response.data.methodology[0].similar)
                    this.setState({
                        version: response.data.methodology[0].version,
                        versions: response.data.methodology[0].versions,
                        similarTab: response.data.methodology[0].similar,
                        timestamp: response.data.methodology[0].timestamp,
                        notes: response.data.methodology[0].note.reverse(),
                        comments: response.data.methodology[0].comment.reverse(),
                        dkbid: response.data.methodology[0].dkbid,
                        title: response.data.methodology[0].title,
                        mainText: Base64.decode(response.data.methodology[0].mainText),
                        resources: Base64.decode(response.data.methodology[0].resources),
                    })
                    const surroundings = gql`
                    query {
                        surroundmeth(query: "${response.data.methodology[0].dkbid}") {
                            next,
                            prev
                        }
                    }`
                    this.client.query({ query: surroundings }).then((response) => {
                        console.log(response.data)
                        this.setState({
                            nButton: response.data.surroundmeth.next,
                            lButton: response.data.surroundmeth.prev
                        })
                    })
                })
            } catch (e) {
                this.props.history.push('/login')
            };
        } else {
            this.props.history.push('/login')
        }
    }

    rerenderForSimilar(uuid) {
        console.log(uuid)
        const queryVariables = gql`
        query {
            methodology (query: "${uuid}") {
                dkbid,
                title,
                mainText,
                resources,
                uuid,
                timestamp,
                comment {
                    uuid,
                    content, 
                    timestamp,
                    name,
                    owner{profilePicture}
                }
                similar {
                    uuid
                    dkbid
                    title
                    otheruuid
                }
                note {
                    uuid,
                    content, 
                    timestamp,
                    name,
                    owner{profilePicture}
                }
                tag {
                    text
                }
                versions {
                    owner{ 
                        name
                    }
                    timestamp
                    version
                }
                version
            },
        }`

        this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
            this.setState({
                selected: uuid,
                version: response.data.methodology[0].version,
                versions: response.data.methodology[0].versions,
                similarTab: response.data.methodology[0].similar,
                timestamp: response.data.methodology[0].timestamp,
                notes: response.data.methodology[0].note.reverse(),
                comments: response.data.methodology[0].comment.reverse(),
                dkbid: response.data.methodology[0].dkbid,
                title: response.data.methodology[0].title,
                mainText: Base64.decode(response.data.methodology[0].mainText),
                resources: Base64.decode(response.data.methodology[0].resources),
            })
            const surroundings = gql`
                query {
                    surroundmeth(query: "${response.data.methodology[0].dkbid}") {
                        next,
                        prev
                    }
                }`
                this.client.query({ query: surroundings }).then((response) => {
                    console.log(response.data)
                    this.setState({
                        nButton: response.data.surroundmeth.next,
                        lButton: response.data.surroundmeth.prev
                    })
                })
        })
    }

    handleChange(e) {
        this.setState({
            value: e.target.value
        })
    }

    handleChange2(e) {
        this.setState({
            value2: e.target.value
        })
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "5px",
            fontSize: "24px"
        }
        const body = {
            fontSize: 20,
            color: '#404040',
            fontFamily: 'Roboto'
        }
        const bodyContainer = {
            marginLeft: 10,
            marginRight: 10,
            marginTop: 6,
            marginBottom: 24,
        }
        const commentBody = {
            fontSize: 12
        }
        const timeStamp = {
            float: 'left',
            fontSize: 9,
            marginTop: 11,
            float: 'left',
            color: 'gray'
        }
        const commentAuthor = {
            color: 'black',
            marginRight: 8,
            marginTop: 5,
            float: 'left'
        }

        return (
            <div>
                <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"></link>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose"><ListAltIcon /></CardIcon>
                                <h4 style={cardIconTitle}>{this.state.title}
                                    {this.state.nButton === "Broken" ? null : <IconButton style={{ float: 'right', padding: 0, margin: 0, width: 28, height: 28 }} size="small" label="Next"><ArrowForwardIcon onClick={() => {this.rerenderForSimilar(this.state.nButton)}} style={{width: '36px', height: '36px'}} /></IconButton>}
                                    <Link to={{ pathname: '/admin/EditMeth', aboutProps: { name: this.state.selected } }}><IconButton style={{ float: 'right', padding: 0, margin: 0, width: 28, height: 28 }} size="small" label="View"><EditIcon style={{ fontSize: 12, color: ViewVulnColor.infrastructure }} /></IconButton></Link>
                                    <IconButton style={{ float: 'right', padding: 0, margin: 0, width: 28, height: 28 }} size="small" label="Back"><ArrowBackIcon onClick={() => {this.rerenderForSimilar(this.state.lButton)}} style={{width: '36px', height: '36px'}} /></IconButton>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Typography style={cardIconTitle} gutterBottom>Details</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.mainText)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>References</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.resources)}</Typography>
                                </div>
                            </CardBody>
                        </Card >
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <NavPills
                            color="danger"
                            tabs={[
                                {
                                    tabButton: "Notes",
                                    tabContent: (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                    <div>
                                                        <Typography style={{ float: 'right', marginRight: 5, fontSize: 50, fontWeight: 8 }}>
                                                            {this.state.dkbid}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ clear: 'both' }}>
                                                        <Typography style={{ float: 'right', marginBottom: 15, fontSize: 15, fontWeight: 4, marginRight: 5 }}>
                                                            {moment.unix(this.state.timestamp).format('DD MMM YYYY')}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ clear: 'both' }}>
                                                        <Divider />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField style={{ width: '98%' }} value={this.state.value2} multiline rows="3" variant="outlined" onChange={this.handleChange2} />
                                                    <CommentButton style={{ marginTop: 5, marginBottom: 20, marginRight: 10, height: 30, padding: 0, float: 'right', backgroundColor: '#F44336', textTransform: 'none', color: 'white' }} onClick={() => this.postNote()}>Submit</CommentButton>
                                                </Grid>
                                            </Grid>
                                            {this.state.notes.map((comment, index) => (
                                                <ListItem key={index.toString()}>
                                                    <Paper elevation={0} style={{ width: '98%', padding: 10 }}>
                                                        <div>
                                                            <div style={{ marginBottom: 10 }}>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={2}>
                                                                        {
                                                                            (() => {
                                                                                if (comment.owner[0] === undefined || comment.owner[0] === null) {
                                                                                    return <Avatar style={{ fontSize: 40, color: '#d6d6d6' }} />
                                                                                } else {
                                                                                    try {
                                                                                        var imageLocation = `${ImageURL}/static/images/128_${comment.owner[0].profilePicture}.png`
                                                                                        console.log(imageLocation)
                                                                                        return <img src={imageLocation} style={{ marginRight: 10, height: 40, width: 40, float: 'left' }} />
                                                                                    } catch (e) {
                                                                                        return <Avatar style={{ fontSize: 40, color: '#d6d6d6' }} />
                                                                                    }
                                                                                }
                                                                            })()
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <div>
                                                                            <Typography style={commentAuthor}>{comment.name}</Typography>
                                                                            <Typography style={timeStamp}>{comment.timestamp}</Typography>
                                                                            {this.state.admin === true ?
                                                                                <>
                                                                                    <IconButton onClick={() => this.deleteNote(comment.uuid)} style={{ float: 'right', marginRight: 5, padding: 5 }}>
                                                                                        <Delete style={{ fontSize: 24 }} />
                                                                                    </IconButton>
                                                                                </>
                                                                                : null}
                                                                        </div>
                                                                        <div style={{ clear: 'both' }}>
                                                                            <Typography style={commentBody}>{ReactHtmlParser(Base64.decode(comment.content))}</Typography>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                </ListItem>
                                            ))}
                                        </>
                                    )
                                },
                                {
                                    tabButton: "Comments",
                                    tabContent: (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                    <div>
                                                        <Typography style={{ float: 'right', marginRight: 5, fontSize: 50, fontWeight: 8 }}>
                                                            {this.state.dkbid}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ clear: 'both' }}>
                                                        <Typography style={{ float: 'right', marginBottom: 15, fontSize: 15, fontWeight: 4, marginRight: 5 }}>
                                                            {moment.unix(this.state.timestamp).format('DD MMM YYYY')}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ clear: 'both' }}>
                                                        <Divider />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField style={{ width: '98%', }} value={this.state.value} multiline rows="3" variant="outlined" onChange={this.handleChange} />
                                                    <CommentButton style={{ marginTop: 5, marginBottom: 20, marginRight: 10, height: 30, padding: 0, float: 'right', backgroundColor: '#F44336', textTransform: 'none', color: 'white' }} onClick={() => this.postComment()}>Submit</CommentButton>
                                                </Grid>
                                            </Grid>
                                            {this.state.comments.map((comment, index) => (
                                                <ListItem key={index.toString()}>
                                                    <Paper elevation={0} style={{ width: '98%', padding: 10 }}>
                                                        <div>
                                                            <div style={{ marginBottom: 10 }}>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={2}>
                                                                        {
                                                                            (() => {
                                                                                if (comment.owner[0] === undefined || comment.owner[0] === null) {
                                                                                    return <Avatar style={{ fontSize: 40, color: '#d6d6d6' }} />
                                                                                } else {
                                                                                    try {
                                                                                        var imageLocation = `${ImageURL}/static/images/128_${comment.owner[0].profilePicture}.png`
                                                                                        return <img src={imageLocation} style={{ marginRight: 10, height: 40, width: 40, float: 'left' }} />
                                                                                    } catch (e) {
                                                                                        return <Avatar style={{ fontSize: 40, color: '#d6d6d6' }} />
                                                                                    }
                                                                                }
                                                                            })()
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <div>
                                                                            <Typography style={commentAuthor}>{comment.name}</Typography>
                                                                            <Typography style={timeStamp}>{comment.timestamp}</Typography>
                                                                            {this.state.admin === true ?
                                                                                <>
                                                                                    <IconButton onClick={() => this.deleteComment(comment.uuid)} style={{ float: 'right', marginRight: 5, padding: 5 }}>
                                                                                        <Delete style={{ fontSize: 24 }} />
                                                                                    </IconButton>
                                                                                </>
                                                                                : null}
                                                                        </div>
                                                                        <div style={{ clear: 'both' }}>
                                                                            <Typography style={commentBody}>{comment.content}</Typography>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                </ListItem>
                                            ))}
                                        </>
                                    )
                                },
                                {
                                    tabButton: "Related",
                                    tabContent: (
                                        <Grid container style={{ width: '100%' }}>
                                            <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                <div>
                                                    <Typography style={{ float: 'right', marginRight: 5, fontSize: 50, fontWeight: 8 }}>
                                                        {this.state.dkbid}
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Typography style={{ float: 'right', marginBottom: 15, fontSize: 15, fontWeight: 4, marginRight: 5 }}>
                                                        {moment.unix(this.state.timestamp).format('DD MMM YYYY')}
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                <div>
                                                    <Typography style={{ float: 'left', marginTop: 14, marginBottom: 15 }}>
                                                        Version
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <IconButton onClick={() => this.setState({ versionHistory: !this.state.versionHistory })} style={{ float: 'right' }}>
                                                        <HistoryIcon style={{ color: 'grey' }} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableCell>DKB-ID</TableCell>
                                                        <TableCell>Title</TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.similarTab.map((result, index) => {
                                                            return (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell><Button style={{ float: 'left' }} onClick={() => this.rerenderForSimilar(result.otheruuid)} style={{ padding: 0, height: 17, width: 80, marginRight: 4, backgroundColor: '#00008b', color: 'white' }} label={result.dkbid}>{result.dkbid}</Button></TableCell>
                                                                        <TableCell>{result.title}</TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    )
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.versionHistory}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'md'}
                >
                    <div style={{ width: 800 }}>
                        <DialogTitle id="alert-dialog-title">{"Version History"}</DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableHead>
                                    <TableCell>Owner's Name</TableCell>
                                    <TableCell>Version</TableCell>
                                    <TableCell>Timestamp</TableCell>
                                </TableHead>
                                <TableBody>
                                    {this.state.versions.map((item, index) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell>{item.owner[0].name}</TableCell>
                                                    <TableCell>v{item.version}</TableCell>
                                                    <TableCell>{item.timestamp}</TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </DialogContent>
                    </div>
                </Dialog>
            </div>
        );
    }

    deleteComment(uuid) {
        const deleteCommentMutate = gql`
            mutation {
                deleteComment(
                    uuid: "${uuid}"
                )
            }
        `

        this.client.mutate({ mutation: deleteCommentMutate }).then(() => {
            const queryVariables = gql`
                query {
                    methodology (query: "${this.props.location.aboutProps.name}") {
                        comment {uuid, content, owner{profilePicture}, name, timestamp}
                    },
                }
            `
            this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                this.setState({
                    comments: response.data.methodology[0].comment.reverse(),
                    value: ""
                })
            })
        })
    }

    handleClose() {
        this.setState({ versionHistory: false })
    }

    deleteNote(uuid) {
        const deleteCommentMutate = gql`
            mutation {
                deleteNote(
                    uuid: "${uuid}"
                )
            }
        `

        this.client.mutate({ mutation: deleteCommentMutate }).then(() => {
            const queryVariables = gql`
                query {
                    methodology (query: "${this.props.location.aboutProps.name}") {
                        note {uuid, content, owner{profilePicture}, name, timestamp}
                    },
                }
            `
            this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                this.setState({
                    notes: response.data.methodology[0].note.reverse(),
                    value: ""
                })
            })
        })
    }

    postNote() {
        if (this.state.value2 !== "") {
            var newArray = []
            newArray.push("<p>")
            var splitArr = this.state.value2.split(" ")
            for (var ukl = 0; ukl<splitArr.length; ukl++) {
                var url = isUrl(splitArr[ukl])
                if (url === false) {
                    newArray.push(splitArr[ukl])
                } else {
                    newArray.push(`<a href="${splitArr[ukl]}" rel="noopener noreferrer" target="_blank">${splitArr[ukl]}</a>`)
                }
            }
            newArray.push("</p>")
            var timestamp = moment().format('DD MMMM YYYY')
            const queryVariables = gql`
            mutation {
                addNote(
                    owner: "${this.state.updateUUID.uuid}",
                    name: "${this.state.updateUUID.name}",
                    profilePicture: "${this.state.updateUUID.profilePicture}",
                    timestamp: "${timestamp}",
                    finding: "${this.props.location.aboutProps.name}",
                    content: "${Base64.encode(newArray.join(" "))}"
                )
                { owner }
            }`

            this.client.mutate({ mutation: queryVariables }).then(() => {
                const queryVariables = gql`
                    query {
                        methodology (query: "${this.props.location.aboutProps.name}") {
                            note {content, owner{profilePicture}, name, timestamp}
                        },
                    }
                `
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    this.setState({
                        notes: response.data.methodology[0].note.reverse(),
                        value2: ""
                    })
                })
            })
        }
    }

    postComment() {
        if (this.state.value !== "") {
            var timestamp = moment().format('DD MMMM YYYY')
            console.log('uuid:', this.state.all.uuid)
            console.log('owner, name, profilePicture:', this.state.updateUUID)
            console.log('content:', this.state.value)
            const queryVariables = gql`
            mutation {
                addSilentComment(
                    owner: "${this.state.updateUUID.uuid}",
                    name: "${this.state.updateUUID.name}",
                    profilePicture: "${this.state.updateUUID.profilePicture}",
                    timestamp: "${timestamp}",
                    finding: "${this.props.location.aboutProps.name}",
                    content: "${this.state.value}"
                )
                { owner }
            }`

            this.client.mutate({ mutation: queryVariables }).then(() => {
                const queryVariables = gql`
                    query {
                        methodology (query: "${this.props.location.aboutProps.name}") {
                            comment {content, owner{profilePicture}, name, timestamp}
                        },
                    }
                `
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    this.setState({
                        comments: response.data.methodology[0].comment.reverse(),
                        value: ""
                    })
                })
            })
        }
    }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewDetails));