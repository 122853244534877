/*eslint-disable*/

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import DashboardView from "views/Dashboard/Dashboard.js"
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import LoginPage from "./views/Auth/login";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const globalStore = (state = { 
  loggedIn: "",
  jwt: "",
  publicKey: "",
  snackbarEdit: false
 }, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        loggedIn: action.payload,
        jwt: action.payload
      };
    case 'SNACKBAR': 
      return {
        ...state, 
        snackbarEdit: action.payload
      }
    case 'UPDATEPUBKEY':
      return {
        ...state,
        publicKey: action.payload
      }
    default:
      return state;
  }
};

const store = createStore(globalStore);
const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/admin/dashboard" component={DashboardView} />
        <Route path="/login" component={LoginPage} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
