/*eslint-disable*/

import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import axios from 'axios';
import ApolloBoost from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { Query } from 'react-apollo';
import { print } from 'graphql';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import jwt from 'jsonwebtoken'
import _ from 'lodash'
import { ImageURL, GraphQL } from "../../apiPack";

// @material-ui/icons
import Contacts from "@material-ui/icons/Contacts";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { connect } from 'react-redux';

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...styles
};

class EditClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updateUUID: '',
      name: '',
      company: '',
      password: '',
      confirmPassword: '',
      profilePicture: "0",
      successBox: false,
      showFreq: true
    }
    this.onChangeStringInput = this.onChangeStringInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.updateDropDown = this.updateDropDown.bind(this);
  }

  client = new ApolloBoost({
    uri: GraphQL,
    request: (operation) => {
      const token = this.props.loggedIn
      console.log('Token: ', token)
      operation.setContext({
        headers: {
          authorization: token ? `${token}` : '',
          "X-Frame-Options": "DENY",
          "X-Content-Type-Options": "nosniff",
          "X-XSS-Protection": 1,
          "Content-Security-Policy": "default-src 'self'",
        }
      })
    }
  })

  componentDidMount() {
    if (this.props.loggedIn !== undefined) {
      try {
        var decoded = jwt.decode(this.props.loggedIn)
        this.setState({ updateUUID: decoded.uuid, error: false })
        const findUsers = gql`
          query {
            consultant(query: "${decoded.uuid}") {
              name
              profilePicture
            }
          }
        `
        this.client.query({
          query: findUsers,
          fetchPolicy: 'no-cache'
        }).then((response) => {
          this.setState({
            name: response.data.consultant[0].name,
            profilePicture: response.data.consultant[0].profilePicture
          })
        })
      } catch (e) {
        this.props.history.push('/login')
      };
    } else {
      this.props.history.push('/login')
    }
  }

  render() {
    const { classes } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Contacts />
              </CardIcon>
              <h4 style={{ color: '#000000', marginTop: "15px", marginBottom: "0px" }}>Edit your profile</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                    Name
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                    id="name"
                    autoComplete="new-password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.onChangeStringInput,
                      value: this.state.name,
                      type: "text",
                      autoComplete: "off"
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                    Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                    id="password"
                    name="password"
                    autoComplete="new-password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.onChangeStringInput,
                      value: this.state.password,
                      type: "text",
                      autoComplete: "new-password"
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                    Confirm Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="new-password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.onChangeStringInput,
                      value: this.state.confirmPassword,
                      type: "text",
                      autoComplete: "off"
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {this.state.showFreq ? <>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                      Profile Picture
                  </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <FormControl fullWidth style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0", marginBottom: "10" }}>
                      <Select
                        value={this.state.profilePicture}
                        onChange={this.updateDropDown}
                      >
                        <MenuItem
                          value="1"
                        >
                          <img src={`${ImageURL}/static/images/128_1.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="2"
                        >
                          <img src={`${ImageURL}/static/images/128_2.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="3"
                        >
                          <img src={`${ImageURL}/static/images/128_3.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="4"
                        >
                          <img src={`${ImageURL}/static/images/128_4.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="5"
                        >
                          <img src={`${ImageURL}/static/images/128_5.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="6"
                        >
                          <img src={`${ImageURL}/static/images/128_6.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="7"
                        >
                          <img src={`${ImageURL}/static/images/128_7.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="8"
                        >
                          <img src={`${ImageURL}/static/images/128_8.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="9"
                        >
                          <img src={`${ImageURL}/static/images/128_9.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="10"
                        >
                          <img src={`${ImageURL}/static/images/128_10.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="11"
                        >
                          <img src={`${ImageURL}/static/images/128_11.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="12"
                        >
                          <img src={`${ImageURL}/static/images/128_12.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="13"
                        >
                          <img src={`${ImageURL}/static/images/128_13.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="14"
                        >
                          <img src={`${ImageURL}/static/images/128_14.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="15"
                        >
                          <img src={`${ImageURL}/static/images/128_15.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                        <MenuItem
                          value="16"
                        >
                          <img src={`${ImageURL}/static/images/128_16.png`} style={{ height: 32, width: 32 }} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </> : null}
              </GridContainer>
              <GridContainer justify="flex-end">
                <GridItem xs={12} sm={12} md={9}>
                  <Button color="rose" onClick={() => this.onSubmit()}>Update</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {this.state.errorFlag ? <SweetAlert
          error
          style={{ display: "block" }}
          onConfirm={this.onConfirm}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnBsStyle="default"
          title="Error"
          timeout={2000}
        >
          Passwords dont match!
          </SweetAlert> : null}
      </GridContainer>
    );
  }

  updateDropDown(e) {
    const value = e.target.value;
    this.setState({
      profilePicture: value
    })
  }

  onChangeStringInput(e) { // On input change, update the state asto the new values
    const {
      id,
      value
    } = e.target;
    this.setState({ [id]: value })
  }

  onConfirm(e) {
    this.setState({
      errorFlag: false
    })
  }

  onSubmit(e) {
    if (this.state.password === undefined) {
      const addNewClient = gql`
        mutation {
          updateProfile(
            uuid: "${this.state.updateUUID}",
            name: "${this.state.name}",
            profilePicture: "${this.state.profilePicture}"
          )
        }
      `

      this.client.mutate({
        mutation: addNewClient
      }).then((response) => {
        this.props.history.push('/admin/dashboard')
      })
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errorFlag: true })
    } else {
      const addNewClient = gql`
        mutation {
          updateProfile(
            uuid: "${this.state.updateUUID}",
            name: "${this.state.name}",
            password: "${this.state.password}",
            profilePicture: "${this.state.profilePicture}"
          )
        }
      `

      this.client.mutate({
        mutation: addNewClient
      }).then((response) => {
        this.props.history.push('/admin/dashboard')
      })
    }
  }
}

const mapStateToProps = function (state) {
  return {
    loggedIn: state.loggedIn,
    publicKey: state.publicKey
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLogged: (input) => dispatch({ type: 'SET', payload: input }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(EditClient))