// Defaults
import React from "react"
import ChartistGraph from "react-chartist"
import withStyles from "@material-ui/core/styles/withStyles"
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import ApolloBoost from 'apollo-boost'
import { Link } from 'react-router-dom'
// @material-ui/core components
import { Chip, Typography, Tooltip, List, ListItem } from "@material-ui/core"
// @material-ui/icons
import Edit from "@material-ui/icons/Edit"
import Refresh from "@material-ui/icons/Refresh"
import AccessTime from "@material-ui/icons/AccessTime"
import ChatIcon from '@material-ui/icons/Chat'
import SubjectIcon from '@material-ui/icons/Subject'
import ScheduleIcon from '@material-ui/icons/Schedule'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import ViewListIcon from '@material-ui/icons/ViewList'
// Template imports
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import Snackbar from "components/Snackbar/Snackbar.js"
import style from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js"
import { GraphQL } from "../../apiPack";
import CircularProgress from '@material-ui/core/CircularProgress';

// Customs
import DashboardColor from "../../data-packs/Dashboard-Color"
import apiPack from "../../apiPack"

class DashboardView extends React.Component {
  constructor() {
    super()

    this.state = {
      error: true,
      notificationFlag: false,
      count: {},
      latestissue: [],
      profilePicture: "",
      userProfile: "",
      snackbarEdit: false,

      users: [],
      conts: []
    }
  }

  client = new ApolloBoost({
    uri: GraphQL,
    request: (operation) => {
      console.log(this.props)
      const token = this.props.loggedIn
      operation.setContext({
        headers: {
          authorization: token ? `${token}` : '',
          "X-Frame-Options": "DENY",
          "X-Content-Type-Options": "nosniff",
          "X-XSS-Protection": 1,
          "Content-Security-Policy": "default-src 'self'",
        }
      })
    }
  })

  componentDidMount() {
    if (this.props.loggedIn !== undefined) {
      try {
        var decoded = jwt.decode(this.props.loggedIn)
        this.setState({ updateUUID: decoded.uuid, profilePicture: decoded.profilePicture, userProfile: decoded.name, admin: decoded.admin })
        if (decoded.admin === true) {
          const queryVariables = gql`
            query {
              consultant (query: "${decoded.uuid}") {
                notification {
                  name
                }
              }
            }
          `
          this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
            if (response.data.consultant[0].notification.length !== 0) {
              this.setState({ notificationFlag: true })
            }
          })
        }
        setTimeout(() => {
          this.props.setSnackbar(false)
        }, 7000);
      } catch (e) {
        this.props.history.push('/login')
      };
    } else {
      this.props.history.push('/login')
    }
    const queryVariables2 = gql`
    query {
      latestissue {
        dkbid
        title
        uuid
      }
      usercontributions {
        name,
        contributions
      }
      count {
        info
        low
        medium
        high
        critical
        TotalFinding
        TotalTags
        TotalNotes
        WebApp
        MobileApp
        Infrastructure
        Wireless
        CodeReview
        BuildReview
        CloudSec
        PhysicalSec
        RedTeam
      }
    }`

    this.client.query({ query: queryVariables2, fetchPolicy: 'no-cache' }).then((response) => {
      console.log('Firing!')
      this.setState({
        latestissue: response.data.latestissue,
        count: response.data.count
      })
      var y = []
      var z = []
      for (var ijo = 0; ijo < response.data.usercontributions.length; ijo++) {
        if (response.data.usercontributions[ijo].contributions < 3) {
          console.log('Skipped one!')
        } else {
          y.push(response.data.usercontributions[ijo].name +'(' + response.data.usercontributions[ijo].contributions + ')')
          z.push(response.data.usercontributions[ijo].contributions)
        }
      }
      this.setState({
        users: y,
        conts: z,
        error: false
      })
    })
  }

  render() {
    var imageLocation = `${apiPack.ImageURL}/static/images/128_${this.state.profilePicture}.png`
    const { classes } = this.props
    var delays2 = 80, durations2 = 500
    const Categories = {
      data: {
        labels: [
          "Web App",
          "Mobile App ",
          "Infrastructure",
          "Wireless Security",
          "Code Review",
          "Build Review",
          "Cloud Security",
          "Physical Security ",
          "Red Team",
        ],
        series: [[this.state.count.WebApp,
        this.state.count.MobileApp,
        this.state.count.Infrastructure,
        this.state.count.Wireless,
        this.state.count.CodeReview,
        this.state.count.BuildReview,
        this.state.count.CloudSec,
        this.state.count.PhysicalSec,
        this.state.count.RedTeam]]
      },
      options: {
        horizontalBars: true,
        maintainAspectRatio: false,
        responsive: true,
        height: 300,
        axisX: {
          showGrid: true
        },
        axisY: {
          offset: 100,
        }
      },
      responsiveOptions: [
        [
          "screen and (max-width: 640px)",
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0];
              }
            }
          }
        ]
      ],
      animation: {
        draw: function (data) {
          if (data.type === "bar") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: "ease"
              }
            });
          }
        }
      }
    }
    const pieChart = {
      data: {
        labels: this.state.users,
        series: this.state.conts
      },
      options: {
        color: 'white',
        height: 250,
        width: 250,
      }
    }
    if (this.state.error === true) {
      return(
        <CircularProgress 
            style={{ 
                position: 'absolute', left: '50%', 
                top: '50%',
                color: '#E42025'
            }} 
        />
      )
    } else {
      return (
        <div>
          <GridContainer>
            {/* Welcome */}
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="rose">
                    <img src={imageLocation} style={{ height: 50, width: 50 }} />
                  </CardIcon>
                  <p className={classes.cardCategory}>Welcome Back!</p>
                  <h3 style={{ textTransform: 'capitalize', color: 'black' }}>{this.state.userProfile}</h3>
                </CardHeader>
                <CardFooter style={{ height: 30 }} stats>
                  <Typography style={{ fontSize: 12, color: DashboardColor.gray }}></Typography>
                </CardFooter>
              </Card>
            </GridItem>
            {/* Total Entries */}
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="rose">
                    <SubjectIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Total Entries</p>
                  <h3 className={classes.cardTitle}>{this.state.count.TotalFinding}</h3>
                </CardHeader>
                <CardFooter style={{ height: 30 }} stats>
                  <Chip style={{ color: 'white', height: 18, width: 110, minWidth: 50, overflow: 'hidden', backgroundColor: DashboardColor.Low }} label={"Low " + this.state.count.low} />
                  <Chip style={{ color: 'white', height: 18, width: 110, minWidth: 50, overflow: 'hidden', backgroundColor: DashboardColor.Medium }} label={"Medium " + this.state.count.medium} />
                  <Chip style={{ color: 'white', height: 18, width: 110, minWidth: 50, overflow: 'hidden', backgroundColor: DashboardColor.High }} label={"High " + this.state.count.high} />
                  <Chip style={{ color: 'white', height: 18, width: 110, minWidth: 50, overflow: 'hidden', backgroundColor: DashboardColor.Critical }} label={"Critical " + this.state.count.critical} />
                </CardFooter>
              </Card>
            </GridItem>
            {/* Total Comments */}
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="rose">
                    <ChatIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Total Comments</p>
                  <h3 className={classes.cardTitle}>{this.state.count.TotalNotes}</h3>
                </CardHeader>
                <CardFooter style={{ height: 30 }} stats>
                  <Typography style={{ fontSize: 12, color: DashboardColor.gray }}></Typography>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {/* Categories Chart */}
            <GridItem xs={12} sm={12} md={4}>
              <Card chart className={classes.cardHover}>
                <CardHeader style={{ height: 320 }} color="info">
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={Categories.data}
                    type="Bar"
                    options={Categories.options}
                    listener={Categories.animation}
                  />
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip
                      id="tooltip-top"
                      title="Refresh"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button simple color="info" justIcon>
                        <Refresh className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="Change Date"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button color="transparent" simple justIcon>
                        <Edit className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  </div>
                  <h4 className={classes.cardTitle}>Categories</h4>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> Number of issues inside categories
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            {/* User Contributions Chart */}
            <GridItem xs={12} sm={12} md={4}>
              <Card chart className={classes.cardHover}>
                <CardHeader style={{ height: 320, textAlign: 'center', stroke: '#dfe6e4' }} color="success">
                  <ChartistGraph
                    class="ct-chart-white-colors"
                    data={pieChart.data}
                    type="Pie"
                    options={pieChart.options}
                  />
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip
                      id="tooltip-top"
                      title="Refresh"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button simple color="info" justIcon>
                        <Refresh className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="Change Date"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button color="transparent" simple justIcon>
                        <Edit className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  </div>
                  <h4 className={classes.cardTitle}>User Contributions</h4>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> Number of issues submitted by the user
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            {/* Recent Additions */}
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="rose">
                    <ViewListIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Recent Additions</p>
                  <h3 className={classes.cardTitle}>Latest Posts</h3>
                </CardHeader>
                <CardBody style={{ height: 334, overflow: 'hidden' }}>
                  <div>
                    <List style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {this.state.latestissue.map((item) => (
                        <div key={item.dkbid + " a c"}>
                          <ListItem key={item.dkbid + " asdkhb"} disableGutters={true} style={{ overflow: 'hidden' }}>
                            <Link key={item.dkbid + "123asdawd"} to={{ pathname: '/admin/ViewDetails', aboutProps: { name: item.uuid } }}><Button key={item + "poihasb"} style={{ padding: 0, height: 18, width: 80, marginRight: 10, backgroundColor: '#df2869', color: 'white' }} >{item.dkbid}</Button></Link>
                            <Typography noWrap key={item.dkbid + "ljbouiybvkjb"} style={{ fontWeight: 4, textOverflow: 'ellipsis' }}>{item.title}</Typography>
                          </ListItem>
                        </div>
                      ))}
                    </List>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <Snackbar
            place="br"
            color="info"
            icon={NotificationImportantIcon}
            message="New notification! View the notification page"
            open={this.state.notificationFlag}
            closeNotification={() => this.setState({ notificationFlag: false })}
            close
          ></Snackbar>
          <Snackbar
            place="br"
            color="success"
            icon={NotificationImportantIcon}
            message={this.state.admin === true ? "Successfully edited!" : "Successfully edited! This change has been sent to the admins"}
            open={this.props.snackbarEdit}
            closeNotification={() => {
              this.props.setSnackbar(false)
            }}
            close
          ></Snackbar>
        </div>
      );
    }
  }
}

const mapStateToProps = function (state) {
  return {
    loggedIn: state.loggedIn,
    snackbarEdit: state.snackbarEdit,
    publicKey: state.publicKey
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    setSnackbar: (input) => dispatch({ type: 'SNACKBAR', payload: input })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(DashboardView));