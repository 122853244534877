import React from "react";
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
// @material-ui/core components
import { Grid, Typography, TextField, IconButton, ListItem, Paper, Divider } from "@material-ui/core/"
import { Switch, FormControlLabel } from "@material-ui/core"
import Avatar from '@material-ui/icons/AccountBox';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import Snackbar from "components/Snackbar/Snackbar.js"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from '@material-ui/core/FormControl';
import MatLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import ListAltIcon from '@material-ui/icons/ListAlt';
import NavPills from "components/NavPills/NavPills.js";
import withStyles from "@material-ui/core/styles/withStyles";
import jwt from 'jsonwebtoken'
// core components
import Button from "components/CustomButtons/Button.js";
import CommentButton from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import { ImageURL } from "../../apiPack";
import { GraphQL } from "../../apiPack";
import Delete from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import isUrl from "is-url"
import { $CombinedState } from "redux";

const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};

class ViewDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            decoded: {},
            adminPage: false,
            newCompName: "",
            normalPage: false,
            profilePicture: "1",
            editUsername: "",
            editUuid: "",
            editName: "",
            snackOpen: "",
            snackMessage: "",
            editPass: "",
            editConfPass: "",
            email: "",
            user: "",
            pass: "",
            admin: false,
            viewOctarianFindings: true,
            editAdmin: false,
            currAdmin: "false",
            companyName: "",
            days: "",
            joinDate: "",
            endDate: "",
            users: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.addUser = this.addUser.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.updateDropDown = this.updateDropDown.bind(this);
        this.toggleAdminPages = this.toggleAdminPages.bind(this)
        this.AdminPageFunction = this.AdminPageFunction.bind(this)
        this.NormalPageFunction = this.NormalPageFunction.bind(this)
        this.updateCompany = this.updateCompany.bind(this)
        this.toggleAdminSwitch = this.toggleAdminSwitch.bind(this)
        this.toggleNormalPages = this.toggleNormalPages.bind(this)
        this.showOctarianFindings = this.showOctarianFindings.bind(this)
        this.openSnackbar = this.openSnackbar.bind(this)
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
              authorization: token ? `${token}` : '',
              "X-Frame-Options": "DENY",
              "X-Content-Type-Options": "nosniff",
              "X-XSS-Protection": 1,
              "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        var decoded = jwt.decode(this.props.loggedIn)
        console.log(decoded)
        this.setState({
            currAdmin: decoded.companyAdmin
        })
        console.log(decoded)
        var getCompany = gql`query {
            company(query: "${decoded.company}") {
                name,
                days,
                seats,
                joinDate,
                endDate,
                active,
                users {
                    uuid,
                    email,
                    name,
                    companyAdmin,
                    contributions
                }
            },
        }`
        this.client.query({ query: getCompany, fetchPolicy: 'no-cache' }).then((response) => {
            this.setState({
                decoded: decoded,
                companyName: response.data.company[0].name,
                newCompName: response.data.company[0].name,
                days: response.data.company[0].days,
                seats: response.data.company[0].seats,
                joinDate: response.data.company[0].joinDate,
                endDate: response.data.company[0].endDate,
                active: response.data.company[0].active,
                users: response.data.company[0].users
            })
            console.log(this.state.currAdmin)
        })
    }

    updateCompany() {
        const addNewClient = gql`
            mutation {
              updateCompany (
                uuid: "${this.state.decoded.company}",
                name: "${this.state.newCompName}"
              )
            }
          `
    
        this.client.mutate({
            mutation: addNewClient
        }).then((response) => {
            this.props.history.push('/login')
        })
    }

    toggleAdminSwitch() {
        this.setState({
            editAdmin: !this.state.editAdmin
        })
    }

    showOctarianFindings() {
        this.setState({
            viewOctarianFindings: !this.state.viewOctarianFindings
        })
    }

    AdminPageFunction() {
        if (this.state.currAdmin !== true) {return null;}

        if (this.state.adminPage === false) {
            return (
                <Grid item xs={6} sm={6} md={6}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose"><ListAltIcon /></CardIcon>
                        </CardHeader>
                        <CardBody>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <h3>Update Company</h3>
                                    <div>
                                        <TextField style={{width: '100%', marginBottom: 10,}} value={this.state.newCompName} id="newCompName" onChange={this.handleChange} label="Company Name">{this.state.newCompName}</TextField>
                                    </div>
                                    <div>
                                        <Button type="submit" onClick={() => {this.updateCompany()}} style={{marginTop: 20, marginBottom: 10}}>Update Company</Button>
                                    </div>
                                </div> 
                                <Divider />
                                <div>
                                    <h3>New User</h3>
                                    <div>
                                        <TextField style={{width: '100%', marginBottom: 10,}} value={this.state.email} id="email" onChange={this.handleChange} label="Email">{this.state.email}</TextField>
                                    </div>
                                    <div>
                                        <TextField style={{width: '100%', marginBottom: 10,}} value={this.state.user} id="user" onChange={this.handleChange} label="Name">{this.state.user}</TextField>
                                    </div>
                                    <div>
                                        <TextField style={{width: '100%', marginBottom: 10,}} value={this.state.pass} id="pass" onChange={this.handleChange} label="Password" type="password">{this.state.pass}</TextField>
                                    </div>
                                    <div>
                                        <FormControlLabel control={<Switch checked={this.state.editAdmin} onChange={() => {this.toggleAdminSwitch()}} color="primary" />} label="Admin" />
                                    </div>
                                    <div>
                                        <Button type="submit" onClick={() => {this.addUser()}} style={{marginTop: 20, marginBottom: 10}}>Add new user</Button>
                                    </div>
                                </div>                                
                            </Grid>
                        </Grid>
                        </CardBody>
                    </Card >
                </Grid>
            )
        } else {
            return (
                    <Grid item xs={6} sm={6} md={6}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose"><ListAltIcon /></CardIcon>
                            </CardHeader>
                            <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                    Username
                                </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                <CustomInput
                                    id="editName"
                                    autoComplete="new-password"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    inputProps={{
                                    disabled: true,
                                    value: this.state.editUsername,
                                    type: "text",
                                    autoComplete: "off"
                                    }}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                    Name
                                </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                <CustomInput
                                    id="editName"
                                    autoComplete="new-password"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    inputProps={{
                                    onChange: this.handleChange,
                                    value: this.state.editName,
                                    type: "text",
                                    autoComplete: "off"
                                    }}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                    Password
                                </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                <CustomInput
                                    id="editPass"
                                    name="password"
                                    autoComplete="new-password"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    inputProps={{
                                    onChange: this.handleChange,
                                    value: this.state.editPass,
                                    type: "password",
                                    autoComplete: "new-password"
                                    }}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                    Confirm Password
                                </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                <CustomInput
                                    id="editConfPass"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    inputProps={{
                                    onChange: this.handleChange,
                                    value: this.state.editConfPass,
                                    type: "password",
                                    autoComplete: "off"
                                    }}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                        Profile Picture
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={5} lg={5}>
                                    <FormControl fullWidth style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0", marginBottom: "10" }}>
                                        <Select
                                            value={this.state.profilePicture}
                                            onChange={this.updateDropDown}
                                        >
                                            <MenuItem
                                            value="1"
                                            >
                                            <img src={`${ImageURL}/static/images/128_1.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="2"
                                            >
                                            <img src={`${ImageURL}/static/images/128_2.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="3"
                                            >
                                            <img src={`${ImageURL}/static/images/128_3.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="4"
                                            >
                                            <img src={`${ImageURL}/static/images/128_4.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="5"
                                            >
                                            <img src={`${ImageURL}/static/images/128_5.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="6"
                                            >
                                            <img src={`${ImageURL}/static/images/128_6.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="7"
                                            >
                                            <img src={`${ImageURL}/static/images/128_7.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="8"
                                            >
                                            <img src={`${ImageURL}/static/images/128_8.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="9"
                                            >
                                            <img src={`${ImageURL}/static/images/128_9.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="10"
                                            >
                                            <img src={`${ImageURL}/static/images/128_10.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="11"
                                            >
                                            <img src={`${ImageURL}/static/images/128_11.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="12"
                                            >
                                            <img src={`${ImageURL}/static/images/128_12.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="13"
                                            >
                                            <img src={`${ImageURL}/static/images/128_13.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="14"
                                            >
                                            <img src={`${ImageURL}/static/images/128_14.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="15"
                                            >
                                            <img src={`${ImageURL}/static/images/128_15.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="16"
                                            >
                                            <img src={`${ImageURL}/static/images/128_16.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                        </Select>
                                        </FormControl>
                                        <div>
                                            <FormControlLabel control={<Switch checked={this.state.editAdmin} onChange={() => {this.toggleAdminSwitch()}} color="primary" />} label="Admin" />
                                        </div>
                                        <div>
                                            <FormControlLabel control={<Switch checked={this.state.viewOctarianFindings} onChange={() => {this.showOctarianFindings()}} color="primary" />} label="View Default Findings" />
                                        </div>
                                        <Button type="submit" onClick={() => {this.updateProfile()}} style={{marginTop: 20, marginBottom: 10}}>Submit</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card >
                    </Grid>
            )
        }
    }

    NormalPageFunction() {
        if (this.state.currAdmin !== false) {return null;}

        if (this.state.normalPage === false) {return null;} else {
            return (
                    <Grid item xs={6} sm={6} md={6}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose"><ListAltIcon /></CardIcon>
                            </CardHeader>
                            <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                    Name
                                </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                <CustomInput
                                    id="editName"
                                    autoComplete="new-password"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    inputProps={{
                                    onChange: this.handleChange,
                                    value: this.state.editName,
                                    type: "text",
                                    autoComplete: "off"
                                    }}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                    Password
                                </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                <CustomInput
                                    id="editPass"
                                    name="password"
                                    autoComplete="new-password"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    inputProps={{
                                    onChange: this.handleChange,
                                    value: this.state.editPass,
                                    type: "text",
                                    autoComplete: "new-password"
                                    }}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                    Confirm Password
                                </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                <CustomInput
                                    id="editConfPass"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    inputProps={{
                                    onChange: this.handleChange,
                                    value: this.state.editConfPass,
                                    type: "text",
                                    autoComplete: "off"
                                    }}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0" }}>
                                        Profile Picture
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={5} lg={5}>
                                    <FormControl fullWidth style={{ display: "inline-flex", lineHeight: "1.428571429", fontWeight: "400", paddingTop: "39px", marginRight: "0", marginBottom: "10" }}>
                                        <Select
                                            value={this.state.profilePicture}
                                            onChange={this.updateDropDown}
                                        >
                                            <MenuItem
                                            value="1"
                                            >
                                            <img src={`${ImageURL}/static/images/128_1.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="2"
                                            >
                                            <img src={`${ImageURL}/static/images/128_2.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="3"
                                            >
                                            <img src={`${ImageURL}/static/images/128_3.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="4"
                                            >
                                            <img src={`${ImageURL}/static/images/128_4.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="5"
                                            >
                                            <img src={`${ImageURL}/static/images/128_5.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="6"
                                            >
                                            <img src={`${ImageURL}/static/images/128_6.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="7"
                                            >
                                            <img src={`${ImageURL}/static/images/128_7.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="8"
                                            >
                                            <img src={`${ImageURL}/static/images/128_8.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="9"
                                            >
                                            <img src={`${ImageURL}/static/images/128_9.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="10"
                                            >
                                            <img src={`${ImageURL}/static/images/128_10.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="11"
                                            >
                                            <img src={`${ImageURL}/static/images/128_11.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="12"
                                            >
                                            <img src={`${ImageURL}/static/images/128_12.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="13"
                                            >
                                            <img src={`${ImageURL}/static/images/128_13.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="14"
                                            >
                                            <img src={`${ImageURL}/static/images/128_14.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="15"
                                            >
                                            <img src={`${ImageURL}/static/images/128_15.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                            <MenuItem
                                            value="16"
                                            >
                                            <img src={`${ImageURL}/static/images/128_16.png`} style={{ height: 32, width: 32 }} />
                                            </MenuItem>
                                        </Select>
                                        </FormControl>
                                        <div>
                                            <FormControlLabel control={<Switch checked={this.state.viewOctarianFindings} onChange={() => {this.showOctarianFindings()}} color="primary" />} label="View Default Findings" />
                                        </div>
                                        <Button type="submit" onClick={() => {this.userUpdateProfile()}} style={{marginTop: 20, marginBottom: 10}}>Submit</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card >
                    </Grid>
            )
        }
    }

    addUser() {
        var decoded = jwt.decode(this.props.loggedIn)
        const queryVariables = gql`
            mutation {
                addCompanyUser(
                    email: "${this.state.email}",
                    name: "${this.state.user}",
                    password: "${this.state.pass}",
                    company: "${decoded.company}",
                    companyAdmin: ${this.state.admin}
                )
            }`
        this.client.mutate({ mutation: queryVariables }).then(() => {
            this.setState({
                email: '',
                user: '',
                pass: '',
                admin: false
            })
            var getCompany = gql`query {
                company(query: "${decoded.company}") {
                    name,
                    days,
                    seats,
                    joinDate,
                    endDate,
                    active,
                    users {
                        uuid,
                        name,
                        companyAdmin,
                        contributions
                    }
                },
            }`
            this.client.query({ query: getCompany, fetchPolicy: 'no-cache' }).then((response) => {
                this.setState({
                    companyName: response.data.company[0].name,
                    days: response.data.company[0].days,
                    seats: response.data.company[0].seats,
                    joinDate: response.data.company[0].joinDate,
                    endDate: response.data.company[0].endDate,
                    active: response.data.company[0].active,
                    users: response.data.company[0].users
                })
                this.openSnackbar("Added user!")
                console.log(this.state.currAdmin)
            })
        })
    }

    updateDropDown(e) {
        const value = e.target.value;
        this.setState({
          profilePicture: value
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    userUpdateProfile(e) {
        console.log(this.state)
        if (this.state.editPass === undefined) {
          const addNewClient = gql`
            mutation {
              updateProfile(
                uuid: "${this.state.editUuid}",
                name: "${this.state.editName}",
                profilePicture: "${this.state.profilePicture}",
                companyAdmin: false,
                viewDefault: ${this.state.viewOctarianFindings}
              )
            }
          `
    
          this.client.mutate({
            mutation: addNewClient
          }).then((response) => {
            console.log(response)
            this.props.setAuth(response.data.updateProfile)
            this.props.history.push('/admin/dashboard')
          })
        } else if (this.state.editPass !== this.state.editConfPass) {
          this.setState({ errorFlag: true })
        } else {
          const addNewClient = gql`
            mutation {
              updateProfile(
                uuid: "${this.state.editUuid}",
                name: "${this.state.editName}",
                password: "${this.state.editPass}",
                profilePicture: "${this.state.profilePicture}",
                companyAdmin: false,
                viewDefault: ${this.state.viewOctarianFindings}
              )
            }
          `
    
          this.client.mutate({
            mutation: addNewClient
          }).then((response) => {
            console.log(response)
            this.props.setAuth(response.data.updateProfile)
            this.props.history.push('/admin/dashboard')
          })
        }
    }

    updateProfile(e) {
        console.log(this.state)
        if (this.state.editPass === undefined) {
          const addNewClient = gql`
            mutation {
              updateProfile(
                uuid: "${this.state.editUuid}",
                name: "${this.state.editName}",
                companyAdmin: ${this.state.editAdmin},
                profilePicture: "${this.state.profilePicture}",
                viewDefault: ${this.state.viewOctarianFindings}
              )
            }
          `
    
          this.client.mutate({
            mutation: addNewClient
          }).then((response) => {
            this.openSnackbar("Successfully Updated")
            this.componentDidMount()
          })
        } else if (this.state.editPass !== this.state.editConfPass) {
          this.setState({ errorFlag: true })
        } else {
          const addNewClient = gql`
            mutation {
              updateProfile(
                uuid: "${this.state.editUuid}",
                name: "${this.state.editName}",
                password: "${this.state.editPass}",
                companyAdmin: ${this.state.editAdmin},
                profilePicture: "${this.state.profilePicture}",
                viewDefault: ${this.state.viewOctarianFindings}
              )
            }
          `
    
          this.client.mutate({
            mutation: addNewClient
          }).then((response) => {
            this.openSnackbar("Successfully updated")
            this.componentDidMount()
          })
        }
    }

    toggleAdminPages(data) {
        console.log(data)
        var myBool = data.companyAdmin == "true" // This returns true or false based on if the person is admin or not
        // Dont touch this above, it's crazy logic

        this.setState({
            editUsername: data.email,
            editUuid: data.uuid,
            editName: data.name,
            adminPage: !this.state.adminPage,
            editAdmin: myBool
        })
    }

    toggleNormalPages(data) {
        console.log(data)
        this.setState({
            editUuid: data.uuid,
            editName: data.name,
            normalPage: !this.state.normalPage,
            editAdmin: false
        })
        setTimeout(() => {
            console.log(this.state)
        }, 5000)
    }

    openSnackbar(message) {
        this.setState({
            snackOpen: true,
            snackMessage: message
        })

        setTimeout(() => {
            this.setState({
                snackOpen: false
            })
        }, 7000)
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "15px",
        }
        const body = {
            fontSize: 20,
            color: '#404040',
            fontFamily: 'Roboto'
        }
        const bodyContainer = {
            marginLeft: 10,
            marginRight: 10,
            marginTop: 6,
            marginBottom: 24,
        }
        const commentBody = {
            fontSize: 12
        }
        const timeStamp = {
            float: 'left',
            fontSize: 9,
            marginTop: 11,
            float: 'left',
            color: 'gray'
        }
        const commentAuthor = {
            color: 'black',
            marginRight: 8,
            marginTop: 5,
            float: 'left'
        }

        return (
            // Change this to the Check details stuff
            <div>
                <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"></link>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose"><ListAltIcon /></CardIcon>
                            </CardHeader>
                            <CardBody>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography style={cardIconTitle} gutterBottom>Company: {this.state.companyName}</Typography>
                                    <Typography style={cardIconTitle} gutterBottom>Subscription Start: {moment.unix(this.state.joinDate).format('DD MMM YYYY')}</Typography>
                                    <Typography style={cardIconTitle} gutterBottom>Subscription End: {moment.unix(this.state.endDate).format('DD MMM YYYY')}</Typography>
                                    <Typography style={cardIconTitle} gutterBottom>Seats: {this.state.users.length} / {this.state.seats}</Typography>
                                        {this.state.currAdmin === true ? <>
                                        <Link key={"123asdawd"} to={{ pathname: '/admin/Queue' }}><Button key={"poihasb"} style={{marginTop: 20, marginBottom: 10}}>Finding's Queue</Button></Link>
                                        <Table aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right">Contributions</TableCell>
                                            <TableCell align="right">Admin</TableCell>
                                            <TableCell align="right">Actions</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.users.map((row) => (
                                            <TableRow key={row.name}>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell align="right">{row.contributions}</TableCell>
                                            <TableCell align="right">{row.companyAdmin}</TableCell>
                                            <TableCell align="right"><Button type="submit" onClick={() => {this.toggleAdminPages(row)}} style={{marginTop: 20, marginBottom: 10}}>Edit Profile</Button></TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                        </Table></> : <><Button type="submit" onClick={() => {this.toggleNormalPages({uuid: this.state.decoded.uuid, name: this.state.decoded.name, companyAdmin: this.state.decoded.companyAdmin})}} style={{marginTop: 20, marginBottom: 10}}>Edit My Profile</Button></>}
                                </Grid>
                            </Grid>
                            </CardBody>
                        </Card >
                    </Grid>
                    <this.AdminPageFunction />
                    <this.NormalPageFunction />
                    <Snackbar
                        place="br"
                        color="success"
                        icon={NotificationImportantIcon}
                        message={this.state.snackMessage}
                        open={this.state.snackOpen}
                        closeNotification={() => this.setState({ snackOpen: false })}
                        close
                    />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
        setAuth: (payload) => dispatch({ type: 'SET', payload: payload }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewDetails));