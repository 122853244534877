import React from "react";
import MaterialTable from 'material-table';
import ApolloBoost from 'apollo-boost';
import gql from "graphql-tag";
import { Link } from 'react-router-dom'
// react component for creating dynamic tables
import ReactTable, { useFlexLayout } from "react-table";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import jwt from 'jsonwebtoken'
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Dialog, List, Checkbox, ListItem, Grid, FormControlLabel, Chip, Typography, Divider, Paper, IconButton } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import CloseIcon from '@material-ui/icons/Close';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { GraphQL } from "../../apiPack";

class ViewVuln extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            setData: [],
            test: [],
            admin: false,
            notification: [],
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.loggedIn !== undefined) {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                if (decoded.admin === false) {
                    this.props.history.push('/login')
                }
                this.setState({ updateUUID: decoded.uuid, admin: decoded.admin })
                const queryVariables = gql`
                    query {
                        deleted {
                            dkbid
                            uuid
                            title
                        }
                    }
                `

                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    this.setState({notification: response.data.deleted})
                })
                setTimeout(() => {
                }, 5000)
            } catch (e) {
                this.props.history.push('/login')
            };
        } else {
            this.props.history.push('/login')
        }
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "15px",
            float: 'left'
        }
        return (
            <div style={{ maxWidth: "100%" }}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 style={cardIconTitle}>View Deleted Findings</h4>
                        <Button style={{float: 'right', textTransform: 'none'}} onClick={() => this.clearAll()}>Clear All</Button>
                    </CardHeader>
                    <CardBody>
                        <div style={{ maxWidth: "100%" }}>
                            <MaterialTable
                                options={{ pageSizeOptions: [10, 25, 100], pageSize: 10 }}
                                columns={[
                                    { title: "DKB-ID", field: "dkbid", width: 150, render: rowData => <div>
                                        <Link to={{ pathname: '/admin/ViewDetails', aboutProps: { name: rowData.uuid } }}><Button style={{ padding: 0, height: 17, width: 80, marginRight: 4, backgroundColor: '#00008b', color: 'white' }} label={rowData.dkbid}>{rowData.dkbid}</Button></Link>
                                    </div> 
                                    },
                                    { title: "Title", field: "title" }
                                ]}
                                data={this.state.notification}
                                title=""
                                components={{
                                    Container: props => props.children
                                }}
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}


const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVuln);