import React from "react";
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
// @material-ui/core components
import { Grid, Typography, TextField, IconButton, ListItem, Paper, Divider } from "@material-ui/core/"
import Avatar from '@material-ui/icons/AccountBox';
import { connect } from 'react-redux';
import MatLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import ListAltIcon from '@material-ui/icons/ListAlt';
import NavPills from "components/NavPills/NavPills.js";
import withStyles from "@material-ui/core/styles/withStyles";
import jwt from 'jsonwebtoken'
// core components
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import { ImageURL } from "../../apiPack";
import { GraphQL } from "../../apiPack";
import Delete from '@material-ui/icons/Delete';

const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};


class ViewDetails extends React.Component {
    constructor() {
        super()
        this.postComment = this.postComment.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.deleteComment = this.deleteComment.bind(this)
        this.approveButton = this.approveButton.bind(this)

        this.state = {
            dkbid: '',
            cvss: '',
            uuid: '',
            comments: [],
            controls: [],
            tags: [],
            cwe: [],
            all: {},
            finding: {},
            techDetails: {},
            remediation: {},
            resources: {},
            value: "",
            justification: '',
            admin: false,
            updateUUID: {}
        }
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.location.aboutProps === undefined) {
            this.props.history.push('/admin/ViewVuln')
        } else if (this.props.loggedIn !== undefined) {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                this.setState({ updateUUID: decoded, admin: decoded.admin, uuid: decoded.uuid })
                const queryVariables = gql`
                query {
                    queued(uuid: "${this.props.location.aboutProps.name}") {
                        uuid,
                        owner,
                        title,
                        cvss,
                        severity,
                        finding,
                        resources,
                        techDetails,
                        justification
                        remediation,
                        findinguuid {
                            dkbid,
                            tag {text},
                            cwe {text},
                            control {text},
                            comment {
                                uuid,
                                content, 
                                timestamp,
                                name,
                                owner{profilePicture}
                            }
                        }
                    },
                }`
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    this.setState({
                        all: response.data.queued[0],
                        dkbid: response.data.queued[0].findinguuid[0].dkbid,
                        tags: response.data.queued[0].findinguuid[0].tag,
                        cvss: response.data.queued[0].cvss,
                        cwe: response.data.queued[0].findinguuid[0].cwe,
                        justification: response.data.queued[0].justification,
                        controls: response.data.queued[0].findinguuid[0].control,
                        finding: Base64.decode(response.data.queued[0].finding),
                        techDetails: Base64.decode(response.data.queued[0].techDetails),
                        remediation: Base64.decode(response.data.queued[0].remediation),
                        resources: Base64.decode(response.data.queued[0].resources),
                        comments: response.data.queued[0].findinguuid[0].comment.reverse()
                    })
                })
            } catch (e) {
                this.props.history.push('/login')
            };
        } else {
            this.props.history.push('/login')
        }
    }

    handleChange(e) {
        this.setState({
            value: e.target.value
        })
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "15px",
        }
        const body = {
            fontSize: 14,
            color: '#404040'
        }
        const bodyContainer = {
            marginLeft: 10,
            marginRight: 10,
            marginTop: 6,
            marginBottom: 24,
        }
        const commentBody = {
            fontSize: 12
        }
        const timeStamp = {
            float: 'left',
            fontSize: 9,
            marginTop: 11,
            float: 'left',
            color: 'gray'
        }
        const commentAuthor = {
            color: 'black',
            marginRight: 8,
            marginTop: 5,
            float: 'left'
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose"><ListAltIcon /></CardIcon>
                                <h4 style={cardIconTitle}>{this.state.all.title}</h4>
                            </CardHeader>
                            <CardBody>
                                <Typography style={cardIconTitle} gutterBottom>Finding</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.finding)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>Technical Details</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.techDetails)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>Remediation</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.remediation)}</Typography>
                                </div>
                                <Typography style={cardIconTitle} gutterBottom>References</Typography>
                                <Divider />
                                <div style={bodyContainer}>
                                    <Typography style={body} varient="caption" gutterBottom>{ReactHtmlParser(this.state.resources)}</Typography>
                                </div>
                            </CardBody>
                        </Card >
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <NavPills
                            color="danger"
                            tabs={[
                                {
                                    tabButton: "Admin",
                                    tabContent: (
                                        <Grid container style={{width: '100%'}}>
                                            <Grid item xs={12} style={{marginBottom: 15}}>
                                                <div>
                                                    <Typography style={{float: 'right', marginRight: 5, fontSize: 50, fontWeight: 8}}>
                                                        {this.state.dkbid}
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Typography style={{float: 'right', marginBottom: 15, fontSize: 15, fontWeight: 4, marginRight: 5}}>
                                                        2nd March '20
                                                    </Typography>
                                                </div>
                                                <div style={{clear: 'both'}}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{marginBottom: 15}}>
                                                <div>
                                                    <Typography style={{fontSize: 18, color: 'black'}}>
                                                        Justification:
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Typography style={{fontSize: 14, marginBottom: 15}}>
                                                        {this.state.justification}
                                                    </Typography>
                                                </div>
                                                <div style={{clear: 'both'}}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button onClick={this.approveButton} style={{float: 'left', width: '100%', backgroundColor: '#4CAF50', color: 'white'}}>Approve</Button>
                                            </Grid>
                                        </Grid>
                                    )
                                },
                                {
                                    tabButton: "Info",
                                    tabContent: (
                                        <Grid container style={{width: '100%'}}>
                                            <Grid item xs={12} style={{marginBottom: 15}}>
                                                <div>
                                                    <Typography style={{float: 'right', marginRight: 5, fontSize: 50, fontWeight: 8}}>
                                                        {this.state.dkbid}
                                                    </Typography>
                                                </div>
                                                <div style={{ clear: 'both' }}>
                                                    <Typography style={{float: 'right', marginBottom: 15, fontSize: 15, fontWeight: 4, marginRight: 5}}>
                                                        2nd March '20
                                                    </Typography>
                                                </div>
                                                <div style={{clear: 'both'}}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{marginBottom: 15, marginBottom: 15}}>
                                                <div>
                                                    <Typography style={{float: 'left'}}>
                                                        CVSS
                                                    </Typography>
                                                    <Typography style={{float: 'right', marginRight: 5, fontSize: 15, fontWeight: 4, marginBottom: 15}}>
                                                        {this.state.cvss}
                                                    </Typography>
                                                </div>
                                                <div style={{clear: 'both'}}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{marginBottom: 15}}>
                                                <div>
                                                    <Typography style={{float: 'left'}}>
                                                        CWE
                                                    </Typography>
                                                    {this.state.cwe.map((item, index) => {
                                                        var splitNumber = item.text.split(' ', 1)
                                                        var number = splitNumber.toString().split('-')
                                                        var value = number[1]
                                                        return (
                                                            <>
                                                                <MatLink style={{float: 'right'}} rel="noopener" target="_blank" href={`https://cwe.mitre.org/data/definitions/${value}.html`}>
                                                                    <Typography style={{fontSize: 14}} gutterBottom>{`CWE-${number[1]}`}</Typography>
                                                                </MatLink>
                                                                <br/><br/>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{clear: 'both'}}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{marginBottom: 15}}>
                                                <div>
                                                    <Typography style={{float: 'left'}}>
                                                        Tags
                                                    </Typography>
                                                    {this.state.tags.map((item, index) => {
                                                        return (
                                                            <>
                                                                <Typography style={{float: 'right', fontSize: 15, fontWeight: 4, marginRight: 5}}>
                                                                    {item.text}
                                                                </Typography>
                                                                <br/><br/>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{clear: 'both'}}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{marginBottom: 15}}>
                                                <div>
                                                    <Typography style={{float: 'left'}}>Controls</Typography>
                                                    {this.state.controls.map((item, index) => {
                                                        return (
                                                            <>
                                                                <Typography style={{float: 'right', fontSize: 15, fontWeight: 4, marginRight: 5}}>
                                                                    {item.text}
                                                                </Typography>
                                                                <br/><br/>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{clear: 'both'}}>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    approveButton() {
        const queryVariables = gql`
            mutation {
                approvedEdit(uuid: "${this.props.location.aboutProps.name}")
            }
        `
        this.client.mutate({ mutation: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
            this.props.history.push('/admin/dashboard')
        })
    }

    deleteComment(uuid) {
        const deleteCommentMutate = gql`
            mutation {
                deleteComment(
                    uuid: "${uuid}"
                )
            }
        `

        this.client.mutate({ mutation: deleteCommentMutate }).then(() => {
            const queryVariables = gql`
                query {
                    finding (query: "${this.props.location.aboutProps.name}") {
                        comment {uuid, content, owner{profilePicture}, name, timestamp}
                    },
                }
            `
            this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                this.setState({
                    comments: response.data.finding[0].comment.reverse(),
                    value: ""
                })
            })
        })
    }

    postComment() {
        if (this.state.value != "") {
            var timestamp = moment().format('Do MMMM [\']YY')
            const queryVariables = gql`
            mutation {
                moment().format('DD MMMM YYYY')(
                    owner: "${this.state.updateUUID.uuid}",
                    name: "${this.state.updateUUID.name}",
                    profilePicture: "${this.state.updateUUID.profilePicture}",
                    timestamp: "${timestamp}",
                    finding: "${this.state.all.uuid}",
                    content: "${this.state.value}"
                )
                { owner }
            }`

            this.client.mutate({ mutation: queryVariables }).then(() => {
                const queryVariables = gql`
                    query {
                        finding (query: "${this.props.location.aboutProps.name}") {
                            comment {content, owner{profilePicture}, name, timestamp}
                        },
                    }
                `
                this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
                    this.setState({
                        comments: response.data.finding[0].comment.reverse(),
                        value: ""
                    })
                })
            })
        }
    }

}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewDetails));