import React from "react";
import ApolloBoost from 'apollo-boost'
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
// @material-ui/core components
import { Grid, Typography, List, TextField, IconButton, ListItem, ListItemText, MenuItem, Checkbox, Input, InputLabel, Select, FormControl, Chip } from "@material-ui/core/"
import uuidv4 from 'uuid/v4'
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken'
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import withStyles from "@material-ui/core/styles/withStyles";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import Autocomplete from '@material-ui/lab/Autocomplete'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import Snackbar from "components/Snackbar/Snackbar.js"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ReactHtmlParser from 'react-html-parser';
import Paper from '@material-ui/core/Paper';
// core components
import { IoIosHelpCircleOutline } from "react-icons/io";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SweetAlert from "react-bootstrap-sweetalert";
import toolTip from "../../assets/css/tooltip-classic.css";
import { GraphQL } from "../../apiPack";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AutoFillData from "../../data-packs/Add-Vuln-Data";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...toolTip,
};

class AddMeth extends React.Component {
  constructor() {
    super()
    this.state = {
      cvss: "",
      cwe: [],
      newSnack: false,
      cwe2: '',
      title: '',
      finding: '',
      technical: '',
      remediation: '',
      resources: "",
      updateUUID: '',
      admin: '',
      uuid: '',
      top100Films: [],
      autocomplete: [],
      mainText: '',
      previewWindow: false,
      tags: [],
      controls: [],

      submitError: "",

      setAlert: false,
      setFail: false,
      setLink: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChange2 = this.handleChange2.bind(this)
    this.handleChange3 = this.handleChange3.bind(this)
    this.handleChange4 = this.handleChange4.bind(this)
    this.handleChange5 = this.handleChange5.bind(this)
    this.handleChange6 = this.handleChange6.bind(this)
    this.handleChange7 = this.handleChange7.bind(this)
    this.handleChange8 = this.handleChange8.bind(this)
    this.handleTitle = this.handleTitle.bind(this)
    this.handleResources = this.handleResources.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.preview = this.preview.bind(this)
    this.onTagsChange = this.onTagsChange.bind(this)
  }

  client = new ApolloBoost({
    uri: GraphQL,
    request: (operation) => {
      const token = this.props.loggedIn
      console.log('Token: ', token)
      operation.setContext({
        headers: {
          authorization: token ? `${token}` : '',
          "X-Frame-Options": "DENY",
          "X-Content-Type-Options": "nosniff",
          "X-XSS-Protection": 1,
          "Content-Security-Policy": "default-src 'self'",
        }
      })
    }
  })

  onTagsChange = (event, values) => {
    console.log(values)
    this.setState({
        autocomplete: values
    });
    setTimeout(() => {
      console.log(this.state.autocomplete)
    }, 1000)
}

  handleChange2(value) {
    this.setState({ technical: value })
  }
  handleChange3(value) {
    this.setState({ remediation: value })
  }
  handleChange4(e) {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleChange5(e) {
    this.setState({
      tags: e.target.value
    })
  }
  handleChange7(e) {
    this.setState({
      controls: e.target.value
    })
  }
  handleChange8(e) {
    this.setState({
      cwe: e.target.value
    })
  }
  handleChange6(value) {
    this.setState({ resources: value })
  }
  handleChange(value1) {
    this.setState({ mainText: value1 })
  }
  handleTitle(e) {
      this.setState({ title: e.target.value })
  }

  componentDidMount() {
    try {
      var decoded = jwt.decode(this.props.loggedIn)
      this.setState({ updateUUID: decoded, admin: decoded.admin, uuid: decoded.uuid })
      const secondQuery = gql`
        query {
            methodology {
                dkbid
                title
                uuid
            }
        }`
      this.client.query({ query: secondQuery, fetchPolicy: 'no-cache' }).then((response) => {
        if (this.state.autocomplete.length !== 0) {
          this.setState({ top100Films: response.data.methodology })
          setTimeout(() => {
            console.log(this.state.autocomplete)
          }, 150);
        } else {
          this.setState({ top100Films: response.data.methodology, autocomplete: [response.data.methodology[0]]})
          setTimeout(() => {
              this.setState({autocomplete: []})
              setTimeout(() => {
                console.log(this.state.autocomplete)
              }, 150);
          }, 50);
        }
      })
    } catch (e) {
      this.props.history.push('/login')
    }
  }

  render() {
    const modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image', 'video', 'table'],
        ['clean']
      ],
    }
    const modules1 = {
      toolbar: ['link']
    }
    const formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'color', 'background',
      'link', 'image', 'video'
    ]
    const formats1 = [
      'link'
    ]

    const headerTop = {
      ...cardTitle,
      color: '#737373',
      fontSize: 20
    }
    const headerTop2 = {
      ...cardTitle,
      color: '#737373',
      fontSize: 20,
      marginBottom: 10
    }
    const headerLower = {
      ...cardTitle,
      color: '#737373',
      fontSize: 20,
      marginTop: 30,
    }
    const cardIconTitle = {
      ...cardTitle,
      fontSize: 22,
      color: '#737373',
      marginTop: "15px",
      marginBottom: "0px"
    }
    const chips = {
      marginRight: 4,
      marginLeft: 4,
      height: 17,
      backgroundColor: "#e91e63",
      color: 'white'
    }

    return (
      <div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose"><MailOutline /></CardIcon>
            <h4 style={cardIconTitle}>Add A Methodology</h4>
          </CardHeader>
          <CardBody>
            <form>
              <Grid style={{ marginBottom: 20 }} container spacing={2}>
                <Grid item xs={3}>
                  <TextField style={{ width: '100%' }} label="Title" id="title" value={this.state.title} onChange={this.handleTitle}></TextField>
                </Grid>
                <Grid item xs={5}>
                  <FormControl style={{ width: '100%', marginBottom: 20 }}>
                    <InputLabel id="tags">Tags</InputLabel>
                    <Select
                      id="tags"
                      value={this.state.tags}
                      onChange={this.handleChange5}
                      renderValue={() => (<>
                        {this.state.tags.map((value, index) => {
                          var x
                          if (value === 'Web Application') {
                            x = ViewVulnColor.webApplication
                          } else if (value === "Mobile Application") {
                            x = ViewVulnColor.mobileApplication
                          } else if (value === "Infrastructure") {
                            x = ViewVulnColor.infrastructure
                          } else if (value === "Wireless") {
                            x = ViewVulnColor.wireless
                          } else if (value === "Code Review") {
                            x = ViewVulnColor.codeReview
                          } else if (value === "Build Review") {
                            x = ViewVulnColor.buildReview
                          } else if (value === "Cloud Security") {
                            x = ViewVulnColor.cloudSecurity
                          } else if (value === "Physical Security") {
                            x = ViewVulnColor.physicalSecurity
                          } else if (value === "Red Team") {
                            x = ViewVulnColor.redTeam
                          }
                          return (
                            <Chip style={{ marginRight: 4, marginLeft: 4, height: 17, backgroundColor: x, color: 'white' }} label={value}>{value}</Chip>
                          );
                        })}
                      </>)}
                      multiple>
                      {AutoFillData.tags.map((tag1, index) => (
                        <MenuItem key={tag1} value={tag1}>{tag1}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                    {this.state.top100Films.length !== 0 ? <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={this.state.top100Films}
                        getOptionLabel={option => option.title}
                        defaultValue={this.state.autocomplete}
                        value={this.state.autocomplete}
                        onChange={this.onTagsChange}
                        style={{ width: '100%', marginBottom: 20 }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option.dkbid} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={params => (
                            <TextField {...params} style={{ width: '100%' }} label="Related" variant="outlined" placeholder=" " />
                        )}
                    /> : null}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography style={headerTop}>Finding</Typography>
                  <ReactQuill style={{ marginTop: 10 }} value={this.state.mainText} onChange={this.handleChange} modules={modules} formats={formats} />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography style={headerTop}>Resources</Typography>
                  <ReactQuill style={{ marginTop: 10, height: 400, marginBottom: 80 }} id="resources" value={this.state.resources} onChange={this.handleResources} modules={modules1} formats={formats1} />
                </Grid>
              </Grid>
              <div style={{ float: 'left', marginRight: 65 }}>
                <span className={`tooltip tooltip-effect-5`}>
                  <span className="tooltip-item">
                    <IoIosHelpCircleOutline style={{ fontSize: 24, float: 'left', marginTop: 30 }} />
                  </span>
                  <span className="tooltip-content clearfix">
                    <span className="tooltip-text">
                      <strong>CVSS</strong> - Please use CVSS v3 calculator to calculate the score. No random numbers here please to save embarrassment <br /><br />
                      <strong>Title</strong> - Title should explain in less than 10 words what the finding is about. <br /><br />
                      <strong>CWE</strong> / OWASP / WASC  - Please assign the appropriate industry standard against the issue being added to the database. <br /><br />
                      <strong>Issue</strong> Category - Assign a right category for the new vulnerability. This could be more than one if an issue is shared across different domains. <br /><br />
                      <strong>Finding</strong> - Finding should provide an overview of the issue in 3 to 4 sentences only. This should detail what has been identified, what is the impact on the environment tested, how it affects CIA triad and finally, if any mitigation is in place. <br /><br />
                      <strong>Technical</strong> Details - Provide as much detail as possible including screenshot and script commands used to identify a vulnerability and exploit it. this.Client should be able to read and replicate themselves. <br /><br />
                      <strong>Remediation</strong> - Please keep the remediation as close as possible to the this.clients environment. Going to the lengths of providing commands / code to patch the issue gets extra brownie points. <br /><br />
                      <strong>Resources</strong> - To use this, add the title name first in the box, click on the link icon and add the hyperlink/URL there. Hit enter. This will create the link to go in the References section of the final report. <br /><br />
                    </span>
                  </span>
                </span>
              </div>
              <Button style={{ marginTop: 20, backgroundColor: AutoFillData.submitButton, marginRight: 10, float: 'right' }} onClick={() => this.submit()}>Submit</Button>
              <Button style={{ marginTop: 20, backgroundColor: AutoFillData.submitButton, float: 'right' }} onClick={() => this.preview()}>Preview</Button>
            </form>
          </CardBody>
        </Card >
        <Snackbar
            place="br"
            color="success"
            icon={NotificationImportantIcon}
            message="Added Methodology!"
            open={this.state.newSnack}
            closeNotification={() => {
              this.props.setSnackbar(false)
            }}
            close
          ></Snackbar>
          <Dialog
            open={this.state.previewWindow}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth = {'lg'}
            fullWidth="true"
          >
            <DialogTitle id="alert-dialog-title">{"Preview"}</DialogTitle>
            <DialogContent>
              <Paper elevation={0} >
                <Typography style={cardIconTitle} gutterBottom>Findings</Typography>
                <Divider />
                <div style={{marginLeft: 10, marginRight: 10, marginTop: 6, marginBottom: 24}}>
                    <Typography style={{fontSize: 14, color: '#404040'}} varient="caption" gutterBottom>{ReactHtmlParser(this.state.mainText)}</Typography>
                </div>
                <br/>
                <Typography style={cardIconTitle} gutterBottom>Resources</Typography>
                <Divider />
                <div style={{marginLeft: 10, marginRight: 10, marginTop: 6, marginBottom: 24}}>
                    <Typography style={{fontSize: 14, color: '#404040'}} varient="caption" gutterBottom>{ReactHtmlParser(this.state.resources)}</Typography>
                </div> 
              </Paper>
            </DialogContent>
          </Dialog>
      </div>
    );
  }

  handleClose() {
    this.setState({
      previewWindow: false
    })
  }

  preview() {
    this.setState({
      previewWindow: true
    })
    setTimeout(() => {
      console.log(this.state.previewWindow)
    }, 5000);
  }

  submit() {
    if (this.state.title === "") {
      this.setState({ setFail: true, submitError: 'Title is missing!' })
    } else {
      var scanUUID = uuidv4()
      console.log(this.state)
      for (var u = 0; u < this.state.tags.length; u++) {
        const queryVariables = gql`
          mutation {
            addTag(
              findingUUID: "${scanUUID}",
              text: "${this.state.tags[u]}"
            )
            {cvss}
          }`
        this.client.mutate({ mutation: queryVariables }).then(() => { })
      }
      for (var uj = 0; uj < this.state.autocomplete.length; uj++) {
        const addControlsMutation = gql`
        mutation {
            addSimilar(
                findinguuid: "${scanUUID}",
                dkbid: "${this.state.autocomplete[uj].dkbid}",
                title: "${this.state.autocomplete[uj].title}",
                otheruuid: "${this.state.autocomplete[uj].uuid}"
            )
        }`
        this.client.mutate({ mutation: addControlsMutation }).then(() => { })
      }
      const queryVariables = gql`
      mutation {
        addMeth(
          owner: "${this.state.uuid}",
          uuid: "${scanUUID}",
          title: "${this.state.title}",
          mainText: "${Base64.encode(this.state.mainText)}",
          resources: "${Base64.encode(this.state.resources)}",
        ) 
      }`
      this.client.mutate({ mutation: queryVariables }).then(() => {
        this.setState({
          title: '',
          mainText: '',
          resources: '',
          tags: [],
          // controls: [],
          autocomplete: [],
          setAlert: true,
          newSnack: true
        })
        setTimeout(() => {
          this.setState({newSnack: false})
        }, 5000)
      })
    }
  }

  handleResources(value) {
    this.setState({
        resources: value
    })
  }
}

const mapStateToProps = function (state) {
  return {
    loggedIn: state.loggedIn,
    publicKey: state.publicKey
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLogged: (input) => dispatch({ type: 'SET', payload: input }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AddMeth));