module.exports = {

    // #############################################################################################################################
    //                                                      DATA
    // #############################################################################################################################
    cwe: [
        // OWASP Top 10 2017 A1 Injection
        {display: 'A-01 Injection', value: "1"},
        {display: 'A-02 Broken Authentication', value: "2" },
        {display: 'A-03 Sensitive Data Exposure', value: "3" },
        {display: 'A-04 XML External Entities (XXE)', value: "4" },
        {display: 'A-05 Broken Access control', value: "5" },
        {display: 'A-06 Security misconfigurations', value: "6" },
        {display: 'A-07 Cross Site Scripting (XSS)', value: "7" },
        {display: 'A-08 Insecure Deserialization', value: "8" },
        {display: 'A-09 Using Components with known vulnerabilities', value: "9" },
        {display: 'A-10 Insufficient logging and monitoring', value: "10" },
    ],

    tags: [
        'Web Application',
        'Mobile Application',
        'Infrastructure',
        'Wireless',
        'Code Review',
        'Build Review',
        'Cloud Security',
        'Physical Security',
        'Red Team',
    ],

    controls: [
        'IAM',
        'Logging',
        'Monitoring',
        'Networking',
        'Session Management',
        'Authentication',
        'Business Logic',
        'Configuration Management',
        'Exception Management',
        'Hardening',
        'Data Validation',
        'Credentials Management'
    ],

    // #############################################################################################################################
    //                                                     COLOURS
    // #############################################################################################################################

    chip: '#e91e63',
    fromColor: '#e91e63',
    headings: '#737373',
    title: '#737373',
    submitButton: '#e91e63'

}
