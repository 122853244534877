/*eslint-disable*/

import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';

// core components
import jwt from 'jsonwebtoken'
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import ViewDetails from "../views/ViewVuln/ViewDetails"
import ViewVuln from "views/ViewVuln/ViewVuln.js"
import DashboardView from "views/Dashboard/Dashboard.js"
import EditVuln from "views/ViewVuln/EditVuln.jsx"
import DeepSearch from "views/ViewVuln/DeepSearch.js"
import CompanyDash from "views/Company/companydash.js"
import AddVuln from "views/AddVuln/AddVuln.js"
import EditProfile from "views/Profile/EditProfile.js"
import ViewEdit from "views/ViewVuln/ViewEdit.jsx"
import AddUser from "views/Profile/AddUser.js"
import Audit from "views/Audit/Audit.js"
import ViewMeth from "views/ViewMeth/ViewMeth.js"
import ViewSpecific from "views/ViewMeth/ViewSpecific.jsx"
import routes from "routes.js";
import adminRoutes from "adminRoutes.js"

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/Main_Transparent_Web.png"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = props.selectedClearClient;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.invisible === true) {
        return null;
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  if (props.loggedIn === undefined) {
    props.history.push('/login')
  } else if (props.loggedIn !== undefined) {
    try {
      var decoded = jwt.decode(props.loggedIn)
      if (decoded.admin === true) {
        console.log('isAdmin, firing inside decoded')
        return (
          <div className={classes.wrapper}>
            <Sidebar
              routes={adminRoutes}
              logoText='Octarian'
              logo={logo}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              bgColor={bgColor}
              miniActive={miniActive}
              {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText="Octarian Knowledge Base (KB) Portal"
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>
                    {getRoutes(adminRoutes)}
                    <Route path="/admin/viewDetails" component={ViewDetails} />
                    <Route path="/admin/company" component={CompanyDash} />
                    <Route path="/admin/profile/edit" component={EditProfile} />
                    <Route path="/admin/AddUser" component={AddUser} />
                    <Route path="/admin/DeepSearch" component={DeepSearch} />
                    <Route path="/admin/AddVuln" component={AddVuln} />
                    <Route path="/admin/ViewVuln" component={ViewVuln} />
                    <Route path="/admin/ViewMeth" component={ViewMeth} />
                    <Route path="/admin/EditVuln" component={EditVuln} />
                    <Route path="/admin/ViewSpecificMeth" component={ViewSpecific} />
                    <Route path="/admin/ViewEdit" component={ViewEdit} />
                    <Route path="/admin/Audit" component={Audit} />
                    <Route path="/admin/dashboard" component={DashboardView} />
                    <Redirect from="/admin" to="/admin/dashboard" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logoText='Octarian'
              logo={logo}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              bgColor={bgColor}
              miniActive={miniActive}
              {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText="Octarian Knowledge Base (KB) Portal"
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>
                    {getRoutes(routes)}
                    <Route path="/admin/viewDetails" component={ViewDetails} />
                    <Route path="/admin/company" component={CompanyDash} />
                    <Route path="/admin/profile/edit" component={EditProfile} />
                    <Route path="/admin/AddUser" component={AddUser} />
                    <Route path="/admin/DeepSearch" component={DeepSearch} />
                    <Route path="/admin/AddVuln" component={AddVuln} />
                    <Route path="/admin/ViewVuln" component={ViewVuln} />
                    <Route path="/admin/ViewMeth" component={ViewMeth} />
                    <Route path="/admin/ViewSpecificMeth" component={ViewSpecific} />
                    <Route path="/admin/EditVuln" component={EditVuln} />
                    <Route path="/admin/ViewEdit" component={ViewEdit} />
                    <Route path="/admin/Audit" component={Audit} />
                    <Route path="/admin/dashboard" component={DashboardView} />
                    <Redirect from="/admin" to="/admin/dashboard" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } catch (e) {
      return (
        <div className={classes.wrapper}>
          <Sidebar
            routes={routes}
            logoText='Octarian'
            logo={logo}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            bgColor={bgColor}
            miniActive={miniActive}
            {...rest}
          />
          <div className={mainPanelClasses} ref={mainPanel}>
            <AdminNavbar
              sidebarMinimize={sidebarMinimize.bind(this)}
              miniActive={miniActive}
              brandText={getActiveRoute(routes)}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
            {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {getRoutes(routes)}
                  <Route path="/admin/viewDetails" component={ViewDetails} />
                  <Route path="/admin/profile/edit" component={EditProfile} />
                  <Route path="/admin/company" component={CompanyDash} />
                  <Route path="/admin/DeepSearch" component={DeepSearch} />
                  <Route path="/admin/AddVuln" component={AddVuln} />
                  <Route path="/admin/ViewVuln" component={ViewVuln} />
                  <Route path="/admin/ViewMeth" component={ViewMeth} />
                  <Route path="/admin/ViewSpecificMeth" component={ViewSpecific} />
                  <Route path="/admin/EditVuln" component={EditVuln} />
                  <Route path="/admin/Audit" component={Audit} />
                  <Route path="/admin/dashboard" component={DashboardView} />
                  <Redirect from="/admin" to="/admin/dashboard" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    props.history.push('/login')
  }
}

const mapState = function (state) {
  return {
    loggedIn: state.loggedIn,
    selectedClient: state.selectedClient,
    selectedClearClient: state.selectedClearClient,
    hosts: state.hosts,
    vulns: state.vulns,
    editVuln: state.editVulnerability,
    publicKey: state.publicKey,
    globalHistory: state.globalHistory
  }
}

const mapDispatch = dispatch => {
  return {
    setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    setClient: (payload) => dispatch({ type: 'SELECTEDCLIENT', payload: payload }),
    addHost: (payload) => dispatch({ type: 'NEWHOST', payload: payload })
  }
}

export default connect(
  mapState,
  mapDispatch
)(Dashboard);
