import React from "react";
import MaterialTable from 'material-table';
import ApolloBoost from 'apollo-boost';
import gql from "graphql-tag";
import { Base64 } from 'js-base64';
import { Link } from 'react-router-dom'
// react component for creating dynamic tables
import ReactTable, { useFlexLayout } from "react-table";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import EditIcon from '@material-ui/icons/Edit';
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import Autocomplete from '@material-ui/lab/Autocomplete'
import ReactHtmlParser from 'react-html-parser';
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardHeader from "components/Card/CardHeader.js";
import jwt from 'jsonwebtoken'
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Dialog, List, Checkbox, ListItem, Grid, FormControlLabel, Chip, Typography, Divider, Paper, IconButton } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import ViewVulnColor from '../../data-packs/View-Vuln-Color'
import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import CloseIcon from '@material-ui/icons/Close';
import { GraphQL } from "../../apiPack";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { tags } from "../../data-packs/Add-Vuln-Data"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import openfilter from '@material-ui/icons/History';

class ViewVuln extends React.Component {
    constructor() {
        super()
        this.state = {
            mData: [],
            addDataOpen: false,
            infoOpen: false,
            editOpen: false,
            addTitle: '',
            addText: '',
            infoTitle: '',
            infoText: '',
            editUuid: '',
            editTitle: '',
            editText: '',
        }

        this.addOpen = this.addOpen.bind(this)
        this.addClose = this.addClose.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.addDialog = this.addDialog.bind(this)
        this.postBackend = this.postBackend.bind(this)
        this.infoOpen = this.infoOpen.bind(this)
        this.infoClose = this.infoClose.bind(this)
        this.infoDialog = this.infoDialog.bind(this)
        this.editOpen = this.editOpen.bind(this)
        this.editClose = this.editClose.bind(this)
        this.editDialog = this.editDialog.bind(this)
        this.postEdit = this.postEdit.bind(this)
        this.deleteManagement = this.deleteManagement.bind(this)
    }

    client = new ApolloBoost({
        uri: GraphQL,
        request: (operation) => {
          const token = this.props.loggedIn
          console.log('Token: ', token)
          operation.setContext({
            headers: {
                authorization: token ? `${token}` : '',
                "X-Frame-Options": "DENY",
                "X-Content-Type-Options": "nosniff",
                "X-XSS-Protection": 1,
                "Content-Security-Policy": "default-src 'self'",
            }
          })
        }
    })

    componentDidMount() {
        if (this.props.loggedIn !== undefined) {
            try {
                var decoded = jwt.decode(this.props.loggedIn)
                this.setState({ updateUUID: decoded.uuid, admin: decoded.admin })
            } catch (e) {
                this.props.history.push('/login')
            };
        } else {
            this.props.history.push('/login')
        }
        const queryVariables = gql`
            query {
                management {
                    uuid,
                    title,
                    text
                },
            }`
        this.client.query({ query: queryVariables, fetchPolicy: 'no-cache' }).then((response) => {
            console.log(response)
            this.setState({mData: response.data.management})
        })
        this.setState({filterOptions: tags})
    }

    addDialog() {
        return (
            <Dialog
                open={this.state.addDataOpen}
                onClose={this.addClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth = {'sm'}
            >
                <DialogTitle id="alert-dialog-title">{"Add management"}</DialogTitle>
                <DialogContent>
                    <Paper elevation={0} >
                        <Grid item xs={12} style={{float: 'left', width: '100%'}}>
                            <TextField
                                name="addTitle"
                                label="Title"
                                margin="normal"
                                value={this.state.addTitle}
                                onChange={this.handleChange}
                                style={{width: "80%", float: 'left'}}
                            />
                            <TextField
                                name="addText"
                                label="Text"
                                margin="normal"
                                value={this.state.addText}
                                onChange={this.handleChange}
                                rows="8"
                                multiline
                                style={{width: "80%", float: 'left'}}
                            />
                            <Button variant="contained" style={{width: "10%", float: 'left', marginTop: '26px', marginLeft: '10px', color: "#FFF", borderColor: "#FFF", backgroundColor: '#E42025'}} onClick={this.postBackend} >
                                Add
                            </Button>
                        </Grid>
                    </Paper>
                </DialogContent>
            </Dialog>
        )
    }

    infoDialog() {
        return (
            <Dialog
                open={this.state.infoOpen}
                onClose={this.infoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth = {'sm'}
            >
                <DialogTitle id="alert-dialog-title">{this.state.infoTitle}</DialogTitle>
                <DialogContent>
                    <Paper elevation={0} >
                        <Grid item xs={12} style={{float: 'left', width: '100%'}}>
                            <Typography varient="caption" gutterBottom>{ReactHtmlParser(this.state.infoText)}</Typography>
                        </Grid>
                    </Paper>
                </DialogContent>
            </Dialog>
        )
    }
    
    editDialog() {
        return (
            <Dialog
                open={this.state.editOpen}
                onClose={this.editClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth = {'sm'}
            >
                <DialogTitle id="alert-dialog-title">{"Edit management"}</DialogTitle>
                <DialogContent>
                    <Paper elevation={0} >
                        <Grid item xs={12} style={{float: 'left', width: '100%'}}>
                            <TextField
                                name="editTitle"
                                label="Title"
                                margin="normal"
                                value={this.state.editTitle}
                                onChange={this.handleChange}
                                style={{width: "80%", float: 'left'}}
                            />
                            <TextField
                                name="editText"
                                label="Text"
                                margin="normal"
                                value={this.state.editText}
                                onChange={this.handleChange}
                                rows="8"
                                multiline
                                style={{width: "80%", float: 'left'}}
                            />
                            <Button variant="contained" style={{width: "10%", float: 'left', marginTop: '26px', marginLeft: '10px', color: "#FFF", borderColor: "#FFF", backgroundColor: '#E42025'}} onClick={this.postEdit} >
                                Update
                            </Button>
                        </Grid>
                    </Paper>
                </DialogContent>
            </Dialog>
        )
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    postBackend() {
        if (this.state.addTitle.length === 0) {return null;}
        if (this.state.addText.length === 0) {return null;}
        const queryVariables = gql`
        mutation {
            addManagement(
                title: "${this.state.addTitle}",
                text: "${Base64.encode(this.state.addText)}"
            ) 
        }`
        this.client.mutate({ mutation: queryVariables }).then(() => {
            this.setState({
                addTitle: '',
                addText: '',
                addDataOpen: false
            })
            this.componentDidMount()
        })
    }

    infoOpen(rowData) {
        console.log('Testing if this is actually firing')
        this.setState({infoOpen: true, infoTitle: rowData.title, infoText: Base64.decode(rowData.text)})
    }

    infoClose() {
        this.setState({infoOpen: false})
    }

    addOpen() {
        this.setState({
            addDataOpen: true
        })
    }

    addClose() {
        this.setState({
            addDataOpen: false
        })
    }

    editOpen(rowData) {
        console.log('Firing insdie edit open')
        this.setState({editOpen: true, editUuid: rowData.uuid, editTitle: rowData.title, editText: Base64.decode(rowData.text)})
    }

    editClose() {
        this.setState({editOpen: false, editUuid: '', editTitle: '', editText: ''})
    }

    postEdit() {
        if (this.state.editTitle.length === 0) {return null;}
        if (this.state.editText.length === 0) {return null;}
        const queryVariables = gql`
        mutation {
            editManagement(
                uuid: "${this.state.editUuid}"
                title: "${this.state.editTitle}",
                text: "${Base64.encode(this.state.editText)}"
            ) 
        }`
        this.client.mutate({ mutation: queryVariables }).then(() => {
            this.setState({
                editTitle: '',
                editText: '',
                editOpen: false
            })
            this.componentDidMount()
        })
    }

    deleteManagement(uuid) {
        const queryVariables = gql`
        mutation {
            deleteManagement(
                uuid: "${uuid}"
            ) 
        }`
        this.client.mutate({ mutation: queryVariables }).then(() => {
            this.componentDidMount()
        })
    }

    render() {
        const cardIconTitle = {
            ...cardTitle,
            marginTop: "15px",
        }
        return (
            <div style={{ maxWidth: "100%" }}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <div style={{ maxWidth: "100%" }}>
                            <Button onClick={() => this.addOpen()}>Add New</Button>
                            <MaterialTable
                                options={{ 
                                    pageSizeOptions: [10, 25, 100], 
                                    pageSize: 10,
                                    headerStyle: {
                                        fontSize: 24 
                                    },
                                }}
                                columns={[
                                    { title: "Title", field: "title", render: rowData => <><p style={{fontSize: 18}} onClick={() => this.infoOpen(rowData)}>{rowData.title}</p></> },
                                    {
                                        title: "Actions", field: "actions", width: 140, render: rowData => <div>
                                           <IconButton size="small" label="View" onClick={() => this.editOpen(rowData)}><Edit style={{ color: '#127dff' }} /></IconButton>
                                           <IconButton size="small" label="View" onClick={() => this.deleteManagement(rowData.uuid)}><CloseIcon style={{ color: '#127dff' }} /></IconButton>
                                        </div>
                                    },
                                ]}
                                title=" "
                                data={this.state.mData}
                            />
                        </div>
                    </CardBody>
                </Card>
                {this.addDialog()}
                {this.infoDialog()}
                {this.editDialog()}
            </div>
        ) 
    }
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.loggedIn,
        publicKey: state.publicKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLogged: (input) => dispatch({ type: 'SET', payload: input }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVuln);